import { TranslationDictionary } from '../translationDictionary.interface';

export const fr: TranslationDictionary = {
  ' Create a new one ': 'Créer un nouveau',
  ' more': ' de plus',
  '#{clParameterType} entered is not valid. Please try another one':
    'Le #{clParameterType} saisi n’est pas valide. Veuillez en essayer un autre',
  "#{msisdn} has been used on target's phone #{imei}":
    '#{msisdn} a été utilisé sur le téléphone ciblé #{imei}',
  '#{socialType} registration started':
    'Enregistrement de #{socialType} démarré',
  '#{socialType} registration succeed. Trying to get email otp code':
    "Enregistrement #{socialType} réussi. Tentative d'obtenir le code Otp par e-mail",
  '1 day': '1 jour',
  '1 hour': '1 heure',
  '1 minute': '',
  '1 week': '1 semaine',
  '15 minutes': '15 minutes',
  '2 days': '2 jours',
  '2-Step Verification': 'Vérification en deux étapes',
  '2-Step Verification is OFF': 'La vérification en deux étapes est désactivée',
  '3 days': '',
  '30 minutes': '30 minutes',
  "A second step after entering your password verifies it's you signing in.":
    "Une deuxième étape après avoir entré votre mot de passe vérifie que c'est bien vous qui vous connectez.",
  ACTIVE: 'ACTIF',
  'AD ID': 'ID publicitaire',
  'ADD ID': '',
  'ADDITIONAL LAYERS': 'COUCHES SUPPLÉMENTAIRES',
  'ALL QUERIES FOUND': 'TOUTES LES REQUÊTES TROUVÉES',
  'ALL UNREACHABLE QUERIES': 'TOUTES LES REQUÊTES INACCESSIBLES',
  APPs: 'Applications',
  'AT&T post-paid only': 'AT&T post-paiement uniquement',
  Abandon: 'Abandonner',
  About: 'À propos de',
  'Accept friends': 'Accepter des amis',
  'Accepting requests': 'Acceptation des demandes',
  'Access to the target will be free for #{days} days':
    "L'accès à la cible sera gratuit pendant #{days} jours",
  Account: 'Compte',
  'Account info': '',
  Accounts: '',
  'Act number': 'Numéro d’acte',
  Action: '',
  'Action has been successfully created': 'L’action a été créée avec succès',
  'Action is started': 'L’action est lancée',
  'Action is stopped': 'L’action est arrêtée',
  'Action started by': 'Action commencée par',
  Actions: 'Actions',
  Activate: 'Activer',
  'Activate 2-step verification': 'Activer la vérification en deux étapes',
  'Activate Now!': 'Activer maintenant!',
  'Activate OTP': 'Activer OTP',
  Activated: 'Activé',
  Active: 'Actif',
  'Active Locate': 'Localisation active',
  'Active in': 'Actif dans',
  'Active locate': 'Localisation active',
  'Active locate query submitted successfully!':
    'La requête de localisation active a été soumise avec succès !',
  'Active proximity items': 'Éléments de proximité actifs',
  Activities: 'Activités',
  Activity: 'Activité',
  'Activity Log': 'Journal des activités',
  'Activity Patterns': 'Modèles d’activité',
  'Activity log': "Journal d'activité",
  'Activity patterns': "Schémas d'activité",
  'Activity timeline': '',
  'Ad IDs': 'IDs publicitaires',
  Add: 'Ajouter',
  Adding: '',
  'Add Group': 'Ajouter un groupe',
  'Add IDs': '',
  'Add MSISDN': 'Ajouter un MSISDN',
  'Add Profile to My Target': 'Ajouter un profil à Ma cible',
  'Add URL': '',
  'Add a Facebook account and click "Enchance target" to retrieve more information':
    'Ajouter un compte Facebook ou cliquer sur "Améliorer la cible" pour récupérer plus d\'informations',
  'Add a new note': 'Ajouter une nouvelle note',
  'Add a note': 'Ajouter une note',
  'Add a valid MSISDN': 'Ajouter un MSISDN valide',
  'Add an existing target': 'Ajouter une cible existante',
  'Add and analyse': 'Ajouter et analyser',
  'Add at least 1 MSISDN or IMEI': 'Ajoutez au moins 1 MSISDN ou IMEI',
  'Add at least 1 MSISDN or IMEI or IMSI':
    'Ajoutez au moins 1 MSISDN ou IMEI ou IMSI',
  'Add case': 'Ajouter un cas',
  'Add court number':
    "Vous êtes sur le point d'effectuer une action qui requiert une autorisation judiciaire. Veuillez entrer l'identifiant numérique de votre autorisation judiciaire.",
  'Add email': 'Ajouter un email',
  'Add entity': 'Ajouter une entité',
  'Add group name': 'Ajouter un nom de groupe',
  'Add hashtag for this query': 'Ajouter un hashtag pour cette requête',
  'Add media content': 'Ajouter du contenu multimédia',
  'Add name and image url': "Ajouter nom et URL d'image",
  'Add new': 'Ajouter un nouveau',
  'Add new Target': 'Ajouter une nouvelle cible',
  'Add new item': 'Ajouter un nouvel élément',
  'Add new keywords list': 'Ajouter une nouvelle liste de mots-clés',
  'Add new place': 'Ajouter un nouveau lieu',
  'Add new platform': 'Ajouter une nouvelle plateforme',
  'Add new value': 'Ajouter une nouvelle valeur',
  'Add note': 'Ajouter une note',
  'Add phone number': 'Ajouter un numéro de téléphone',
  'Add profile to target': 'Ajouter un profil à la cible',
  'Add seeds and click "Enchance target" to get "Most common faces"':
    'Ajoutez des graines et cliquez sur "Améliorer l\'objectif" pour obtenir "Visages les plus courants"',
  'Add seeds and click "Enchance target" to retrieve employment and education':
    'Ajouter des sources et cliquer sur "Améliorer la cible" pour récupérer des informations sur l\'emploi et l\'éducation',
  'Add seeds and click "Enchance target" to retrieve instant messaging apps':
    'Ajouter des sources et cliquer sur "Améliorer la cible" pour récupérer des applications de messagerie instantanée',
  'Add seeds and click "Enchance target" to retrieve media':
    'Ajouter des sources ou cliquer sur "Améliorer la cible" pour récupérer des médias',
  'Add seeds and click "Enchance target" to retrieve relationship information':
    'Ajouter des sources et cliquer sur "Améliorer la cible" pour récupérer des informations sur les relations',
  'Add seeds and click "Enchance target" to retrieve social profiles':
    'Ajouter des sources et cliquer sur "Améliorer la cible" pour récupérer des profils sociaux',
  'Add seeds or Perform an OSINT query to retrieve more information':
    "Ajouter des graines ou effectuer une requête OSINT pour récupérer plus d'informations",
  'Add selected targets': 'Ajouter les objectifs sélectionnés',
  'Add social network': 'Ajouter un réseau social',
  'Add social profiles to the targets to view data':
    'Ajoutez des profils sociaux aux cibles pour voir les données',
  'Add target': 'Ajouter une cible',
  'Add targets': 'Ajouter des cibles',
  "Add targets by searching for target's Alias.": '',
  'Add targets by searching for target’s Alias.':
    'Ajoutez des cibles en recherchant l’alias de la cible.',
  'Add to "My target"': 'Ajouter à "Ma cible"',
  'Add to an existing target': 'Ajouter à une cible existante',
  'Add to case': 'Ajouter au cas',
  'Add to target': 'Ajouter à la cible',
  'Add to Mission': '',
  'Add user': 'Ajouter un utilisateur',
  'Add from recent searches': '',
  Added: 'Ajoutée',
  'Added Details': 'Détails ajoutés',
  'Added Note': 'Note ajoutée',
  'Added Photo': 'Photo ajoutée',
  'Added Seed': 'Semence ajoutée',
  'Added on': 'Ajouté le',
  Address: 'Adresse',
  'Address(es)': 'Adresse(s)',
  Addresses: 'Adresses',
  Adint: 'Adint',
  'Adint history': 'Historique de Adint',
  Admin: 'Administrateur',
  Advanced: 'Avancé',
  'Advanced Geo Query': 'Recherche géographique avancée',
  'Advanced Information Request': "Demande d'informations avanc",
  'Advanced Options': 'Options avancées',
  'Advanced Webint': 'WebInt avancé',
  'Advanced Webint query created successfully!':
    'Requête Webint avancée créée avec succès !',
  'Advanced geolocation': 'Géolocalisation avancée',
  'Advanced osint': 'OSINT avancé',
  'Advanced search will add media, relations, insights and more from social profiles':
    'La recherche avancée ajoutera des médias, des relations, des informations et plus encore provenant de profils sociaux.',
  'Advanced search will gather more intel from MSISDN, email & social profiles and analyse their content such as media, relations, posts and more':
    "La recherche avancée rassemblera plus d'informations du MSISDN, des emails et des profils sociaux et analysera leur contenu tels que les médias, les relations, les publications et plus encore",
  'Advanced webint is already in progress.': 'Webint avancé est déjà en cours.',
  'Affiliated person': 'Personne affiliée',
  Afghanistan: 'Afghanistan',
  Age: 'Âge',
  'Age range': 'Tranche d’âge',
  'Age of Location': '',
  Albania: 'Albanie',
  'Album name': "Nom de l'album",
  'Album url': "URL de l'album",
  Alerts: '',
  Algeria: 'Algérie',
  Alias: 'Alias',
  'Alias exists. Please use another alias':
    'Cet alias existe déjà. Veuillez en utiliser un autre',
  'Alias is too short': "L'alias est trop court",
  Aliases: 'Alias',
  All: 'Tout',
  'All IP addresses': 'Toutes les adresses IP',
  'All MSISDNs': 'Tous les numéros de téléphone',
  'All NIK': '',
  'All Site names': 'Tous les sites',
  'All Types': 'Tous les types',
  'All accounts': '',
  'All addresses': 'Toutes les adresses',
  'All associated domains': 'Tous les domaines associés',
  'All caught up': 'Tout est à jour',
  'All companies': 'Tous les organisations',
  'All contacts': '',
  'All courses': 'Tous les cours',
  'All educations': 'Toutes les éducations',
  'All emails': 'Tous les e-mails',
  'All family relations': 'Toutes les relations familiales',
  'All fans': 'Tous les fans',
  'All friend relations': 'Toutes les relations d’amitié',
  'All groups': 'Tous les groupes',
  'All hashtags': 'Tous les hashtags',
  'All industries': 'Toutes les industries',
  'All jobs': 'Tous les emplois',
  Landline: '',
  'All landlines': 'Toutes les lignes fixes',
  'All locations': 'Tous les emplacements',
  'All mentions': 'Toutes les mentions',
  'All messages': '',
  'All names': 'Tous les noms',
  'All organizations': 'Toutes les organisations',
  'All other info': 'Toutes les autres informations',
  'All other relations': 'Toutes les autres relations',
  'All phone numbers': 'Tous les numéros de téléphone',
  'All places': 'Tous les lieux',
  'All sent messages': '',
  'All skills': 'Toutes les compétences',
  'All social platforms': 'Toutes les plateformes sociales',
  'All sources': 'Toutes les sources',
  'All the answers are anonymous.': 'Toutes les réponses sont anonymes.',
  'All the data collected will be completely anonymous.':
    'Toutes les données collectées seront complètement anonymes.',
  'All types': 'Tous les types',
  'All urls': 'Toutes les URL',
  'All usernames': 'Tous les utilisateurs',
  'All users': 'Tous les utilisateurs',
  'All vehicle ID (VIN)': 'Tous les ID de véhicules',
  'All vehicle make': 'Toutes les marques de véhicules',
  'All vehicle models': 'Tous les modèles de véhicules',
  'All volunteering actions': 'Toutes les actions de bénévolat',
  'All week': 'Toute la semaine',
  'All work relations': 'Toutes les relations professionnelles',
  'Allowed file types: images': 'Types de fichiers autorisés : images',
  'Always obtain accurate geolocation information when available':
    'Obtenez toujours des informations de géolocalisation précises lorsque disponibles',
  'Always obtain the peer details and geolocate when available':
    'Obtenir les détails du participant à l’appel et géolocaliser lorsqu’ils sont disponibles',
  'Always obtain the peer details when available':
    'Obtenir les détails du participant à l’appel lorsqu’ils sont disponibles',
  'Always show descriptions': 'Toujours afficher les descriptions',
  Amount: 'Montant',
  'An email with the QR was sent to': 'Un email avec le QR a été envoyé à',
  'An error has occurred': "Une erreur s'est produite",
  'An international #{type} to or from #{msisdn} coming from #{country} has happened at least #{frequency} time(s).':
    'Une #{type} internationale vers ou depuis #{msisdn} provenant de #{country} s’est produite au moins #{frequency} fois.',
  Analyse: 'Analyser',
  'Analysing data': 'Analyse de données',
  Analysis: 'Analyse',
  'Analysis includes': "L'analyse comprend",
  Analytics: 'Analytique',
  Analyze: 'Analyser',
  'Analyze in progress': 'Analyse en cours',
  'Analyze location': "Analyser l'emplacement",
  'Analyze locations': 'Analyser les emplacements',
  'Analyzed content': 'Contenu analysé',
  'Analyzing Results': 'Analyse des résultats',
  'Analyzing and merging the results for':
    'Analyse et fusion des résultats pour',
  'Analyzing and unmerging the results for':
    'Analyse et annulation de la fusion des résultats pour',
  'Analyzing results': 'Analyse des résultats',
  And: 'et',
  'And more': 'et plus encore',
  Andorra: 'Andorre',
  Angola: 'Angola',
  Anguilla: 'Anguilla',
  'Antigua and Barbuda': 'Antigua-et-Barbuda',
  Any: "N'importe quel",
  Anytime: "N'importe quand",
  'AoI Query': 'Requête AoI',
  Application: 'Application',
  Applications: 'Applications',
  Apply: 'Appliquer',
  Apps: 'Applications',
  April: 'avril',
  Archive: 'Archive',
  'Are you still in the same investigation?':
    'Êtes-vous toujours dans la même enquête ?',
  'Are you sure want to clear all the history ?':
    "Êtes-vous sûr de vouloir effacer tout l'historique ?",
  'Are you sure want to clear the history ?':
    "Êtes-vous sûr de vouloir effacer l'historique ?",
  'Are you sure want to delete the Activity log entry?': '',
  'Are you sure you want to delete case permanently?':
    'Êtes-vous sûr de vouloir supprimer définitivement le cas ?',
  'Are you sure you want to delete investigation permanently?':
    'Êtes-vous sûr de vouloir supprimer définitivement cette enquête ?',
  'Are you sure you want to delete target permanently?':
    'Êtes-vous sûr de vouloir supprimer définitivement la cible ?',
  'Are you sure you want to delete this search?':
    'Êtes-vous sûr de vouloir supprimer cette recherche?',
  'Are you sure you want to disable the geofencing?':
    'Êtes-vous sûr de vouloir désactiver la géolocalisation ?',
  'Are you sure you want to leave the program?':
    'Êtes-vous sûr de vouloir quitter le programme ?',
  'Are you sure you want to remove target from case permanently?':
    'Êtes-vous sûr de vouloir supprimer définitivement la cible du cas ?',
  'Are you sure you want to remove target from this case?':
    'Êtes-vous sûr de vouloir supprimer la cible de ce cas ?',
  'Are you sure you want to remove this target?': '',
  'Are you sure you want to reset the user password for':
    "Êtes-vous sûr de vouloir réinitialiser le mot de passe de l'utilisateur pour",
  'Are you sure you wish to resubmit?':
    'Êtes-vous sûr de vouloir soumettre à nouveau ?',
  'Are you sure you wish to save this result?':
    'Êtes-vous sûr de vouloir enregistrer ce résultat ?',
  Area: 'Zone',
  'Area of Interest Query': "Requête d'intérêt",
  'Area of interest': "Zone d'intérêt",
  Argentina: 'Argentine',
  Armenia: 'Arménie',
  Articles: 'Articles',
  Ascending: 'Ascendant',
  'Assign to target or add to existing':
    "Attribuer à la cible ou ajouter à l'existante",
  'Assigned targets changed': 'Cibles assignées modifiées',
  'Associated MSISDN with account': 'MSISDN associé au compte',
  'Associated domains': 'Domaines associés',
  'Associated with': 'Associé à',
  'Attended same institution': 'A fréquenté la même institution',
  August: 'août',
  Australia: 'Australie',
  Austria: "L'Autriche",
  'Authenticating to system': 'Authentification au système',
  'Auto detect': 'Auto detect',
  'Autocomplete all': 'Tout compléter automatiquement',
  'Automatic interactions': 'Interactions automatiques',
  'Available Credits': 'Crédits disponibles',
  'Available Data': 'Données disponibles',
  'Available to assign': 'Disponible pour assigner',
  Avatar: 'Avatar',
  'Avatar id': "Identifiant d'avatar",
  'Avatar in error state, please contact administrators':
    "L'avatar est dans un état d'erreur, veuillez contacter les administrateurs.",
  'Avatar remote view ended': "La vue distante de l'avatar est terminée",
  Avatars: 'Avatars',
  'Average activity': 'Activité moyenne',
  'Average matches per session': 'Correspondances moyennes par session',
  Azerbaijan: 'Azerbaïdjan',
  'BASE LAYERS': 'COUCHES DE BASE',
  BLOCKED: 'BLOQUÉ',
  BUSY: 'OCCUPÉ',
  Back: 'Retour',
  'Back to "Cases and targets"': 'Retour à "Cas et cibles"',
  'Back to "Core"': 'Retour à "Core"',
  'Back to Call Logs': "Retour aux journaux d'appels",
  'Back to Case': 'Retour au cas',
  'Back to Dashboard': 'Retour au tableau de bord',
  'Back to login': 'Retour à la connexion',
  'Back to previous query': 'Retour à la requête précédente',
  Bahrain: 'Bahreïn',
  Balance: 'Solde',
  Bangladesh: 'Bangladesh',
  'Bank details': 'Détails bancaires',
  Barbados: 'Barbade',
  Basic: 'Basique',
  'Basic OSINT': 'OSINT Basique',
  'Basic OSINT Search': 'Recherche OSINT de base',
  'Basic OSINT will discover more seeds':
    "L'OSINT basique découvrira plus de sources",
  'Basic Osint query created successfully!':
    'Requête Osint de base créée avec succès !',
  Belarus: 'Biélorussie',
  Belgium: 'Belgique',
  Belize: 'Bélize',
  Benin: 'Bénin',
  Bermuda: 'Bermudes',
  Betweenness: 'Intermédiaire',
  Bhutan: 'Bhoutan',
  'Billing error': 'Erreur de facturation',
  Bio: 'Biographie',
  'Birth date': 'Date de naissance',
  'Birth location': 'Lieu de naissance',
  Birthday: 'Anniversaire',
  Blacklist: 'Liste noire',
  'Blacklist number': 'Numéro de liste noire',
  Blacklisted: '',
  Block: 'Bloquer',
  Blocked: 'Bloqué',
  Bolivia: 'Bolivie',
  'Bosnia and Herzegovina': 'Bosnie Herzégovine',
  'Both screenWidth and screenHeight must be specified':
    "Les deux valeurs de largeur et hauteur de l'écran doivent être spécifiées.",
  Botswana: 'Bostwana',
  Brazil: 'Brésil',
  'Breached results for': 'Résultats violés pour',
  'British Indian Ocean Territory': "Territoire britannique de l'océan Indien",
  'British Virgin Islands': 'Îles Vierges britanniques',
  Bright: '',
  Broadcaster: 'Présentateur',
  Browse: '',
  'Browse files': 'Parcourir les fichiers',
  Browser: 'Navigateur',
  Brunei: 'Brunéi',
  Bulgaria: 'Bulgarie',
  'Burkina Faso': 'Burkina Faso',
  Burundi: 'Burundi',
  Busy: 'Occupé',
  'Button to start adding content to it': '',
  CALLEE: 'Numéro appelé',
  CALLER: 'Numéro appelant',
  'CDR Statistics': 'Statistiques de CDR',
  CURP: 'CURP',
  CVE: '',
  Calculate: '',
  Calculating: '',
  'Calculates the extended connections of a node. Can identify nodes with influence over the whole network, and not just those directly connected to it.':
    'Calcule les connexions étendues d’un nœud. Peut identifier les nœuds ayant une influence sur l’ensemble du réseau, et pas seulement ceux qui y sont directement connectés.',
  Call: 'Appel',
  'Call Analysis': 'Analyse des appels',
  'Call Log': "Journal d'appels",
  'Call Log Analysis': "Analyse du journal d'appels",
  'Call Log Cancel Request': "Demande d'annulation du journal d'appels",
  'Call Log MSISDN': 'MSISDN du journal des appels',
  'Call Log Pending': "Journal d'appels en attente",
  'Call Log Request': "Demande de journal d'appels",
  'Call Log Request Pending': "Demande de journal d'appels en attente",
  'Call Log for': 'Journal des appels pour',
  'was uploaded successfully': '',
  'is importing': '',
  'could not be mapped automatically': '',
  'has failed to upload': '',
  'Call Log has been requested': 'Le journal d’appels a été demandé',
  'Call Logs': 'Journal des appels',
  'Call Logs uploaded successfully!':
    "Registres d'appels téléchargés avec succès !",
  'Call analysis': "Analyse d'appel",
  'Call detail records for': "Détails des enregistrements d'appels pour",
  'Call duration': "Durée de l'appel",
  'Call in': "Entrée d'appel",
  'Names from Call ID Apps': '',
  'Call log': "Journal d'appels",
  'Call log MSISDN is different from target MSISDN.':
    "Le MSISDN du journal d'appels est différent du MSISDN cible.",
  'Call log analysis': "Analyse du journal d'appels",
  'Call log map': 'Carte du journal des appels',
  'Call log pending': "Journal d'appels en attente",
  'Call log recommandation archived.':
    'Recommandation de journal des appels archivée.',
  'Call log recommandation unarchived.':
    'Recommandation de journal des appels désarchivée.',
  'Call log record': 'Journal des appels',
  'Call log report in PDF': "Rapport de journal d'appels en PDF",
  'Call log request #{type} for #{duration} months':
    'Demande de journaux d’appels pour #{type} de #{duration} mois',
  'Call log request is already in pending':
    "La demande de journal d'appels est en attente",
  'Call log requested': 'Journal des appels demandé',
  'Call log successfully processed!': "Journal d'appels traité avec succès !",
  'Call log top associates': "Principaux associés du journal d'appels",
  'Call log top relation': 'Relation importante du journal des appels',
  'Call log upload for #{type}':
    'Téléchargement de journaux d’appels pour #{type}',
  'Call log upload request for author':
    "Demande de téléchargement du journal d'appels pour l'auteur",
  'requested at': 'demandé à',
  'has been canceled and refunded': 'a été annulé et remboursé',
  'Call logs': "Journal d'appels",
  'Call logs interaction depth':
    "Profondeur d'interaction des journaux d'appels",
  'Call logs should be uploaded as a single .csv file':
    "Les journaux d'appels doivent être téléchargés en tant que fichier .csv unique",
  'Call logs should be uploaded as a single .csv or .xlsx file':
    "Les journaux d'appels doivent être téléchargés sous forme d'un seul fichier .csv ou .xlsx",
  'Call out': "Sortie d'appel",
  Called: 'Appelé',
  Callee: 'Appelé',
  Caller: 'Appelant',
  Callerid: 'Callerid',
  Calls: 'Appels',
  'Calls and SMS': 'Appels et SMS',
  'Calls duration': 'Durée des appels',
  'Calls number': "Nombre d'appels",
  Cambodia: 'Cambodge',
  Cameroon: 'Cameroun',
  Canada: 'Canada',
  Cancel: 'Annuler',
  'Cancel Request sent successfully':
    "Demande d'annulation envoyée avec succès",
  'Cancel all uploads': '',
  'Cancel request sent successfully!': 'Demande annulée avec succès!',
  Cancelled: '',
  'Cancelled request': 'Demande annulée',
  'Cannot update schema': '',
  'Cannot upload file': 'Impossible de télécharger le fichier',
  'Cape Verde': 'Cap-Vert',
  'Card number': 'Numéro de carte',
  Cardinal: 'Cardinal',
  Carrier: 'Opérateur',
  Case: 'Cas',
  'Case Call Analysis': "Analyse d'appels de cas",
  'Case Cdr Statistics': 'Statistiques de CDR de cas',
  'Case Count Analysis': 'Analyse de comptage de cas',
  'Case Event By Type': 'Événement par type de cas',
  'Case Target Activity': "Activité de la cible de l'affaire",
  'Case Top Associates': 'Personnes les plus importantes du cas',
  'Case Top Locations': 'Lieux les plus importants du cas',
  'Case call logs report': "Rapport de journal d'appels de l'affaire",
  'Case colour changed': 'Couleur du dossier modifiée',
  'Case created successfully!': 'Cas créé avec succès !',
  'Case has not been created': "Le cas n'a pas été créé",
  'Case has not been edited': "Le cas n'a pas été modifié",
  'Case has not been renewed': "L'affaire n'a pas été renouvelée",
  'Case is expired': 'Le cas est expiré',
  'Case name changed': 'Nom du dossier modifié',
  'Case renewed successfully!': 'Affaire renouvelée avec succès!',
  'Case targets': 'Cibles du cas',
  'Case updated successfully!': 'Cas mis à jour avec succès !',
  Cases: 'Cas',
  'Cases and Targets': 'Cas et Cibles',
  'Cases and targets': 'Cas et objectifs',
  'Cases duration': '',
  Catcher: 'Récepteur',
  Category: 'Catégorie',
  'Cayman Islands': 'Îles Caïmans',
  'Cdr Export': 'Exporter CDR',
  'Cdr Statistics': 'Statistiques de CDR',
  'Cdr Type': 'Type de CDR',
  Cell: 'Cellule',
  'Cell ECI': 'ECI de cellule',
  'Cell ENBID': 'ENBID de cellule',
  'Cell Id': 'ID de cellule',
  'Cell ID': 'ID de cellule',
  'Cell LCID': 'LCID de cellule',
  'Cell id': '',
  'Central African Republic': 'République centrafricaine',
  'Cell tower': '',
  Certainty: 'Certitude',
  Chad: 'Tchad',
  Characterized: '',
  Change: 'Changement',
  'Change OTP method': 'Changer de méthode OTP',
  'Change Password': 'Changer le mot de passe',
  'Change language': 'Changer de langue',
  'Change password': 'Changer le mot de passe',
  'Change your password': 'Changer votre mot de passe',
  'Changes saved successfully!':
    'Les modifications ont été enregistrées avec succès !',
  'Chat Sentiment': 'Chat Sentiment',
  'Chat details': '',
  'Chat summary': 'Chat summary',
  Chats: '',
  'Check for spelling errors': "Vérifiez les fautes d'orthographe",
  'Check-ins': 'Lieux',
  'Checkin details': "Détails de l'enregistrement",
  'Checking Data Availability...':
    'Vérification de la disponibilité des données ...',
  Chile: 'Chili',
  China: 'Chine',
  Choose: 'Choisir',
  'Choose Duration': 'Choisir la durée',
  'Choose Frequency': 'Choisir la fréquence',
  'Choose age range': "Choisir une tranche d'âge",
  'Choose an interval': 'Choisir un intervalle',
  'Choose duration and frequency': 'Choisir la durée et la fréquence',
  'Choose file to upload': 'Choisissez le fichier à télécharger',
  'Choose image to upload': "Choisir l'image à télécharger",
  'Choose media for upload': 'Choisir des médias à télécharger',
  'Choose profiles or seeds to build your target':
    'Choisissez des profils ou des graines pour construire votre cible',
  'City name': 'Ville',
  Clear: 'Effacer',
  'Clear All': 'Tout effacer',
  'Clear all': 'Effacer tout',
  'Clear all filters': 'Effacer tous les filtres',
  'Clear filters': 'Effacer les filtres',
  'Clear map': 'Effacer la carte',
  'Clear selection': 'Effacer la sélection',
  'Click for more options': "Cliquez pour plus d'options",
  'Click on the button below to renew for #{days} days':
    'Cliquez sur le bouton ci-dessous pour renouveler pendant #{days} jours',
  'Click on the button to minimise the search process and continue with your tasks':
    '',
  'Click to see details': 'Cliquez pour voir les détails',
  Close: 'Fermer',
  'Close all': 'Fermer tout',
  'Close session details': 'Fermer les détails de session',
  'Closing this process before the Virtual ID logs in successfully will likely cause issues in operating it.':
    '',
  Collapse: 'Réduire',
  'Collected Profile URL': 'URL de profil collectée',
  'Collecting and analysing intelligence for':
    'Collecte et analyse de renseignements pour',
  Colombia: 'Colombie',
  Colony: 'Colonie',
  Color: 'Couleur',
  'Column settings': 'Paramètres de colonne',
  Command: 'Commande',
  'Comment on posts': 'Commenter les publications',
  'Commented on a photo of the target': 'A commenté une photo de la cible.',
  'Commented on a post of the target':
    'A commenté une publication de la cible.',
  Commenters: 'Commentateurs',
  'Common Associates': 'Personnes communes',
  'Common Groups': 'Common Groups',
  'Common Ips': 'IP communes',
  'Common Locations': 'Lieux communs',
  'Common education places': "Lieux d'éducation communs",
  'Common faces on photos': 'Visages communs sur les photos',
  'Common locations': 'Lieux communs',
  'Common relations': 'Relations communes',
  'Common relations on social networks':
    'Relations communes sur les réseaux sociaux',
  'Common working places': 'Lieux de travail communs',
  'Communication Channel': 'Canal de communication',
  Communities: '',
  'Community Details': 'Community Details',
  Comoros: 'Comores',
  Companies: 'Entreprises',
  Company: 'Entreprise',
  'Company Employees': "Employés de l'entreprise",
  'Company Logo': "Logo de l'entreprise",
  'Company Logo Url': "URL du logo de l'entreprise",
  'Company employee': "Employé de l'entreprise",
  'Company location': 'Emplacement de la société',
  Completed: 'Terminé',
  'Completed at': 'Terminé à',
  'Completed successfully': 'Terminé avec succès',
  Confidence: 'Confiance',
  Confidential: 'Confidentiel',
  'Confidential content': 'Contenu confidentiel',
  'Configure keywords list': 'Configurer la liste de mots-clés',
  'Configuring Gmail': 'Configuration de Gmail',
  'Configuring acc.': 'Configuration du compte',
  Confirm: 'Confirmer',
  'Confirm New Password': 'Confirmer le nouveau mot de passe',
  'Confirm new password': 'Confirmer le nouveau mot de passe',
  Confirmation: 'Confirmé',
  'Confirmation required': 'Confirmation requise',
  'Confirmed Infection': 'Infection Confirmée',
  'Confirmed infection': 'Infection Confirmée',
  'Congo-Brazzaville': 'Congo Brazzaville',
  'Connected numbers by device for': 'Numéros connectés par appareil pour',
  'Connected numbers by location for':
    'Numéros connectés par localisation pour',
  'Connected with MSISDN': 'Connecté(e) avec MSISDN',
  'Connected with this location': 'Connecté à cet emplacement',
  Connections: 'Connexions',
  'Connections Details': '',
  Consec: 'Consec',
  'Contact Customer Success': 'Contacter le succès client',
  'Contact Us': 'Contactez-nous',
  'Contact’s active groups': '',
  Contains: 'Contient',
  Content: 'Contenu',
  Continue: 'Continuer',
  'Conversation Type': 'Type de conversation',
  'Cook Islands': 'les Îles Cook',
  Coordinates: 'Coordonnées',
  Copied: 'Copié',
  'Copied!': 'Copié !',
  Copy: 'Copier',
  'Copy URL': "Copier l'URL",
  'Copy all': 'Tout copier',
  'Copy link': 'Copier le lien',
  'Copy location': "Copier l'emplacement",
  'Copy name': 'Copier le nom',
  'Copy password': 'Copier le mot de passe',
  'Copy to Clipboard': 'Copier dans le presse-papiers',
  'Copy value': '',
  Core: 'Core',
  Correlator: 'Corrélateur',
  'Costa Rica': 'Costa Rica',
  'Could not determine the target’s status':
    'Impossible de déterminer le statut de la cible',
  'Could not find a person with exact input':
    'Impossible de trouver une personne avec une entrée exacte',
  'Could not gather data for this report':
    'Impossible de recueillir des données pour ce rapport',
  'Could not gather data for this target':
    'Impossible de collecter des données pour cette cible',
  'Could not reset password': 'Impossible de réinitialiser le mot de passe',
  'Could not save!': "Impossible d'enregistrer !",
  'Could not update target profile, user id already exists!':
    "Impossible de mettre à jour le profil de la cible, l'identifiant utilisateur existe déjà !",
  'Could not verify that you are human':
    'Impossible de vérifier que vous êtes humain',
  'Could not verify that you are human, please contact your administrator':
    'Impossible de vérifier que vous êtes humain, veuillez contacter votre administrateur',
  Count: 'Compte',
  'Count analysis': 'Analyse de comptage',
  Countries: 'Pays',
  Country: 'Pays',
  'Country N/A': 'Pays N/A',
  'Country and region': 'Pays et région',
  Courses: 'Cours',
  'Covid vaccination status': 'Statut de vaccination COVID',
  'Covid vaccine': 'Vaccin Covid',
  Create: 'Créer',
  'Create Place': 'Créer un lieu',
  'Create Target': 'Créer une cible',
  'Create User': 'Créer un utilisateur',
  'Create a Geofencing Task (1 week / 15 minutes)':
    'Créer une tâche de géorepérage (1 semaine / 15 minutes)',
  'Create a Geofencing Task (6 hours / 15 minutes)':
    'Créer une tâche de géorepérage (6 heures / 15 minutes)',
  'Create a Schedule Task (1 week / 15 minutes)':
    'Créer une tâche planifiée (1 semaine / 15 minutes)',
  'Create a Schedule Task (6 hours / 15 minutes)':
    'Créer une tâche planifiée (6 heures / 15 minutes)',
  'Create a case': 'Créer un cas',
  "Create a case with the target's alias":
    "Créer un dossier avec l'alias de la cible",
  'Create a new target': 'Créez une nouvelle cible',
  'Create a new target and Enhance profile':
    'Créer une nouvelle cible et améliorer le profil',
  'Create a new target and Request call log':
    "Créer une nouvelle cible et demander le journal d'appels",
  'Create a new target and find possible associates, visited places, connections on social media, groups and more.':
    'Créer une nouvelle cible et trouver des associés potentiels, des lieux visités, des connexions sur les réseaux sociaux, des groupes et plus encore.',
  'Create a target': 'Créer une cible',
  'Create a temporary user': 'Créer un utilisateur temporaire',
  'Create case': 'Créer un dossier',
  'Create case investigation': 'Créer une enquête de cas',
  'Create group': 'Créer un groupe',
  'Create new case': 'Créer un nouveau cas',
  'Create new case or search for existing one':
    'Créer un nouveau dossier ou rechercher un dossier existant',
  'Create new keywords list': 'Créer une nouvelle liste de mots-clés',
  'Create new list': 'Créer une nouvelle liste',
  'Create new target': 'Créer une nouvelle cible',
  'Create new target or search for existing one':
    'Créez une nouvelle cible ou recherchez-en une existante',
  'Create or add to target': 'Créer ou ajouter à la cible',
  'Create post': 'Créer la publication',
  'Create post was successfully created':
    'La publication a été créée avec succès',
  'Create target': 'Créer une cible',
  Created: 'Créé',
  'Created On': 'Créé le',
  'Created at': 'Créé le',
  Creating: 'Création en cours',
  'Credit cards': '',
  'Credit change for request_cdr for IMEI':
    'Changement de crédit pour la demande de CDR pour IMEI',
  'Credit usage': 'Utilisation de crédit',
  Credits: 'Crédits',
  'Credits Usage Statistics': 'Statistiques d’utilisation des crédits',
  'Credits are expired': 'Les crédits sont expirés',
  'Credits will be deducted only if accurate information is found.':
    'Les crédits ne seront déduits que si des informations précises sont trouvées.',
  Critical: 'Critique',
  Croatia: 'Croatie',
  'Cross Intel Search': 'Recherche croisée Intel',
  Crypto: '',
  Cuba: 'Cuba',
  Cumulative: 'Cumulatif',
  Current: 'Actuel',
  'Current And Previous Workplaces (Target)':
    'Lieu de travail actuel et précédent (Cible)',
  'Current Call': 'Appel en cours',
  'Current Password': 'Mot de passe actuel',
  'Current call': 'Appel en cours',
  'Current city': 'Ville actuelle',
  'Current password': 'Mot de passe actuel',
  'Current target has no MSISDN attached!':
    "La cible actuelle n'a aucun MSISDN attaché !",
  'Custom report': 'Rapport personnalisé',
  Cv: 'CV',
  Cve: 'CV',
  Cyprus: 'Chypre',
  Czechia: 'Tchéquie',
  "Côte d'Ivoire": "Côte d'Ivoire",
  'Côte dIvoire': '',
  DATA: 'Données',
  DEACTIVATED: 'DÉSACTIVÉ',
  DEBUG: 'Débogage',
  'Daily activity': 'Activité quotidienne',
  'Daily credits assigned': 'Crédits quotidiens assignés',
  'Daily credits available': 'Crédits quotidiens disponibles',
  'Daily quota': 'Quota quotidien',
  'Daily quota assigned': 'Quota quotidien assigné',
  Dark: '',
  'Dark Web': 'Web sombre',
  Darkweb: 'Darkweb',
  Dashboard: 'Tableau de bord',
  Data: 'Données',
  'Data collected at': 'Data collected at',
  'Data saved successfully!': 'Les données ont été enregistrées avec succès !',
  'Data transferred': 'Données transférées',
  Date: 'Date',
  'Date & time': 'Date et heure',
  'Date Of Birth': 'Date de naissance',
  'Date Of Query': 'Date de la requête',
  'Date Period': '',
  'Date added': '',
  'Date and time': 'Date et heure:',
  'Date created': 'Date de création',
  'Date of birth': 'Date de naissance',
  'Date of creation': 'Date de création',
  'Date of query': 'Date de la requête',
  Dates: 'Dates',
  'Dates of birth': 'Dates de naissance',
  'Day first': '',
  'Day of month': 'Jour du mois',
  'Day of week': 'Jour de la semaine',
  'De Anon': 'Dé-anonymiser',
  Deactivate: 'Désactiver',
  'Deactivate 2-step verification': 'Désactiver la vérification en deux étapes',
  'Deactivate OTP': 'Désactiver OTP',
  Dear: 'Cher',
  December: 'décembre',
  Deep: 'Approfondi',
  'Deep Webint': 'Webint approfondi',
  'Deep Webint query created successfully!':
    'Requête WebInt profonde créée avec succès!',
  'Deep search will add second degree relations, likers, commenters, reactors and more':
    'La recherche approfondie ajoutera des relations de deuxième degré, des personnes ayant aimé, commenté, réagi et plus encore.',
  'Define quota': 'Définir le quota',
  Degrees: 'Degrés',
  Delete: 'Supprimer',
  'Delete Group': 'Supprimer le groupe',
  'Delete Target': 'Supprimer la cible',
  'Delete case': 'Supprimer le cas',
  'Delete file': 'Supprimer le fichier',
  'Delete photo': 'Supprimer la photo',
  'Delete target': 'Supprimer la cible',
  'Deleted!': 'Supprimé !',
  'Democratic Republic of the Congo': 'République Démocratique du Congo',
  Denmark: 'Danemark',
  Deposit: '',
  Descending: 'Descendant',
  Description: 'Description',
  Destination: 'Destination',
  Details: 'Détails',
  'Details for': 'Détails',
  'Details panel': 'Panneau de détails',
  Device: 'Appareil',
  'Device ID': "ID de l'appareil",
  'Device usage': '',
  'Device coordinates': '',
  Devices: '',
  Direction: 'Direction',
  Disable: 'Désactiver',
  'Disable drawing': 'Désactiver le dessin',
  'Disable email notifications': 'Désactiver les notifications par e-mail',
  'Disable sms notifications': 'Désactiver les notifications par SMS',
  'Disable target heatmap': "Désactiver la carte de chaleur de l'objectif",
  'Disable target history timeline':
    "Désactiver la chronologie de l'historique de l'objectif",
  'Disable traffic layer': 'Désactiver la couche de trafic',
  Disabled: 'Désactivée',
  'Disabled Feature': 'Fonctionnalité désactivée',
  Discard: 'Abandonner',
  Disclaimer: 'Avertissement',
  'Disclaimer content': "Contenu de l'avertissement",
  Discover: 'Découvrir',
  'Discover more seeds': 'Découvrir plus de sources',
  'Discover persons': 'Découvrir des personnes',
  'Discovered persons': 'Personnes découvertes',
  Discovery: 'Découverte',
  Discovery2: 'Découverte2',
  'Discovery will be updated on 12:00': '',
  'Dislike posts': 'Ne plus aimer les publications',
  Distance: 'Distance',
  'Distance from target': 'Distance de la cible',
  'Distribution of keywords on platforms':
    'Répartition des mots-clés sur les plateformes',
  'Distribution of topics': 'Répartition des sujets',
  Djibouti: 'Djibouti',
  'Do you want to extract details about the Call Peer':
    'Voulez-vous extraire des détails sur le participant à l’appel ?',
  'Do you want to extract the Peer Caller ID?':
    "Voulez-vous extraire l'identifiant de l'appelant ?",
  'Do you want to extract the Peer Caller ID? This action will deduct 4 credits.':
    "Voulez-vous extraire l'identifiant de l'appelant ? Cette action déduira 4 crédits.",
  'Do you want to get cell level location?':
    'Voulez-vous obtenir la localisation de la tour ?',
  'Do you want to perform an Active Locate query? This action might leave anonymous traces on target’s phone.':
    'Voulez-vous effectuer une requête de localisation active ? Cette action pourrait laisser des traces anonymes sur le téléphone de la cible.',
  'Do you wish to perform an intelligence query or view the existing intel?':
    "Souhaitez-vous effectuer une requête d'intelligence ou consulter l'intelligence existante ?",
  'Do you wish to perform an intelligence query?':
    "Souhaitez-vous effectuer une requête d'intelligence ?",
  'Do you wish to reload the chart? Any data created from analysing nodes will be removed':
    "Voulez-vous recharger le graphique? Toutes les données créées à partir de l'analyse des nœuds seront supprimées.",
  Domain: 'Domaine',
  Dominica: 'Dominique',
  'Dominican Republic': 'République dominicaine',
  Done: 'Done',
  'Double click to expand/collapse node':
    'Double-cliquez pour agrandir/réduire le nœud',
  Download: 'Télécharger',
  'Download CSV': 'Télécharger le CSV',
  'Download file': '',
  'Download report': 'Télécharger le rapport',
  Downloading: 'Téléchargement en cours',
  'Drag and Drop file here or click to browse':
    'Drag and Drop file here or click to browse',
  'Drag and drop to add an MSISDN to the investigation':
    'Faites glisser et déposez pour ajouter un MSISDN à l’enquête',
  'Drag and drop to load a case to the investigation':
    'Faites glisser et déposez pour charger un dossier dans l’enquête',
  'Drag and drop to load a target to the investigation':
    'Faites glisser et déposez pour charger une cible dans l’enquête',
  'Drag and drop to upload file for Text analysis': '',
  'Drag file here': 'Déplacez le fichier ici',
  'Drag image here': "Déplacez l'image ici",
  'Drag media here': 'Faites glisser les médias ici',
  'Drag the map to precise your location':
    'Faites glisser la carte pour préciser votre emplacement',
  'Drag to link with another node': 'Faites glisser pour lier à un autre nœud',
  Draw: 'Dessiner',
  'Draw area': 'Dessiner la zone',
  'Draw circle': 'Dessiner un cercle',
  'Draw marker': 'Dessiner un marqueur',
  'Drugs and illegal substances': 'Drogues et substances illégales',
  Duplicated: 'Dupliqué',
  Duration: 'Durée',
  'Duration (s)': 'Durée (s)',
  'During the importing process, please do not log out, close this tab or transition to another website':
    '',
  "E.g., 'I work with drug cases in Panama' or 'I work with kidnapping investigations in Mexico'.":
    "Par exemple, 'Je travaille sur des cas de drogue au Panama' ou 'Je travaille sur des enquêtes de kidnapping au Mexique'.",
  ERROR: 'ERREUR',
  'Earliest activity': '',
  'East Timor': 'Timor oriental',
  Ecuador: 'Equateur',
  Edit: 'Modifier',
  'Edit Group': 'Modifier le groupe',
  'Edit Target': 'Modifier la cible',
  'Edit a target': 'Modifier une cible',
  'Edit family member': 'Modifier un membre de la famille',
  'Edit name': 'Modifier le nom',
  'Edit places': 'Modifier les lieux',
  Education: 'Éducation',
  'Education (Target)': 'Éducation (Cible)',
  Educations: 'Éducation',
  Egypt: 'Egypte',
  'Eigen centrality': 'Centralité de l’Eigen',
  'El Salvador': 'Le Salvador',
  Email: 'E-mail',
  'Email address': 'Adresse e-mail',
  'Email sent.': 'Email envoyé.',
  'Email to support': 'Envoyer un email au support',
  'Email(s)': 'Adresse(s) e-mail',
  Emails: 'Adresses e-mail',
  Emergency: 'Urgence',
  'Employment and education': 'Emploi et éducation',
  'Empty space': '',
  'Enable Call log analysis': 'Activer l’analyse du journal des appels',
  'Enable OTP': 'Activer OTP',
  'Enable email notifications': 'Activer les notifications par e-mail',
  'Enable geofencing': 'Activer la géolocalisation',
  'Enable layer': 'Activer la couche',
  'Enable sms notifications': 'Activer les notifications par SMS',
  'Enable support requests': 'Activer les demandes de support',
  'Enable target heatmap': "Activer la carte de chaleur de l'objectif",
  'Enable target history timeline':
    "Activer la chronologie de l'historique de l'objectif",
  'Enable traffic layer': 'Activer la couche de trafic',
  'End Date': 'Date de fin',
  'End date': 'Date de fin',
  Ending: '',
  English: 'Anglais',
  'Enhance Profile': 'Améliorer le Profil',
  'Enhance Profile query created successfully!':
    "Requête d'amélioration de profil créée avec succès!",
  'Enhance profile': 'Améliorer le profil',
  'Enhance profile in order to automatically create a target, discover more intelligence information and retrieve important insights':
    "Améliorez le profil pour créer automatiquement une cible, découvrir plus d'informations de renseignement et récupérer des informations importantes.",
  'Enhance profile in order to discover more intelligence information and retrieve important insights':
    "Améliorez le profil pour découvrir plus d'informations de renseignement et récupérer des informations importantes.",
  'Enrich Profile': 'Enrich Profile',
  'Enrich profile': '',
  'Enrich target with Somedus data': '',
  'Enter Ad ID(s)': 'Entrez les ID publicitaires',
  'Enter IMEI': "Entrez l'IMEI",
  'Enter MSISDN': 'Entrez le MSISDN',
  'Enter OTP': 'Entrer le code OTP',
  'Enter Password': 'Entrer le mot de passe',
  'Enter URL': 'Entrer l’URL',
  'Enter a address': 'Entrez une adresse',
  'Enter a location': 'Entrer une localisation',
  'Enter a MSISDN or IMSI': '',
  'Enter a number': 'Veuillez entrer un numéro',
  'Enter a short description of your investigation':
    'Entrez une brève description de votre enquête',
  'Enter a valid IMEI': 'Veuillez entrer un IMEI valide',
  'Enter a valid IMSI': 'Veuillez entrer un IMSI valide',
  'Enter a valid TMSI': 'Veuillez entrer un TMSI valide',
  'Enter a valid number': 'Veuillez entrer un numéro valide',
  'Enter a valid phone': 'Veuillez entrer un téléphone valide',
  'Enter a valid username': "Veuillez entrer un nom d'utilisateur valide",
  'Enter a valid value': 'Veuillez entrer une valeur valide',
  'Enter a value': 'Entrez une valeur',
  'Enter alias': 'Entrer un alias',
  'Enter coordinates: lat, lon': 'Entrez les coordonnées: lat, lon',
  'Enter emails': 'Entrez des emails',
  'Enter investigation name': 'Entrez le nom de l’enquête',
  'Enter notes': 'Entrer des notes',
  'Enter password': 'Entrez le mot de passe',
  'Enter phone': 'Entrez le téléphone',
  'Enter phone numbers': 'Entrez les numéros de téléphone',
  'Enter report title': 'Entrer le titre du rapport',
  'Enter the OTP code from your device': 'Entrez le code OTP de votre appareil',
  'Enter the code received via SMS': 'Entrez le code reçu par SMS',
  'Enter the code received via email': 'Entrez le code reçu par e-mail',
  'Enter the complete phone number including country code. Example: #{example}':
    'Entrez le numéro de téléphone complet, y compris le code pays. Exemple : #{example}',
  'Enter the keywords': 'Entrez les mots clés',
  'Enter the text': 'Entrez le texte',
  'Enter valid coordinates': 'Entrez des coordonnées valides',
  'Enter value': 'Entrez une valeur',
  Entities: '',
  Entity: 'Entité',
  'Entity Type': '',
  'Equatorial Guinea': 'Guinée Équatoriale',
  Equipment: '',
  Eritrea: 'Érythrée',
  'Error occured while attempting to reserve':
    'Une erreur s’est produite lors de la tentative de réservation',
  'Error while uploading file': 'Erreur lors du téléchargement du fichier',
  Errors: 'Erreurs',
  Estonia: 'Estonie',
  Ethiopia: 'Ethiopie',
  Event: 'Événement',
  'Event viewer': "Visionneuse d'événements",
  'Events Type': "Type d'événement",
  'Events by type': 'Événements par type',
  'Events found': 'Événements trouvés',
  'Every MSISDN should start with + .': 'Chaque MSISDN doit commencer par + .',
  Exchange: '',
  'Existing target': 'Cible existante',
  Exit: '',
  'Exit full screen': 'Quitter le mode plein écran',
  'Exit without log in': '',
  Expand: 'Développer',
  'Expiration date': "Date d'expiration",
  Expired: 'Expiré',
  'Expired Avatar': 'Avatar expiré',
  'Expired cases will be renewed': 'Les dossiers expirés seront renouvelés',
  'Expired targets will be renewed and added to case':
    'Les cibles expirées seront renouvelées et ajoutées au dossier',
  'Expires On': '',
  'Expires on': 'Expire le',
  'Expiring date': "Date d'expiration",
  Export: 'Exporter',
  'Export CSV': 'Exporter en CSV',
  'Export PDF': 'Exporter en PDF',
  'Export as CSV': 'Exporter en CSV',
  'Export call log': "Exporter le journal d'appels",
  'Export call log report': 'Exporter le rapport du journal des appels',
  'Export current investigation view': 'Exporter la vue d’enquête actuelle',
  'Export detailed geolocation report':
    'Exporter le rapport de géolocalisation détaillé',
  'Export graph': 'Exporter le graphique',
  'Export selected': 'Exporter la sélection',
  'Export table': 'Exporter la table',
  'Export to CSV': 'Exporter au format CSV',
  'Export to pdf': 'Exporter en PDF',
  Exporting: 'Exportation',
  'Exporting CSV': 'Exportation CSV',
  'Exporting call log': "Export du journal d'appels",
  'Exporting file': 'Exportation du fichier',
  'Exporting table in CSV': 'Exportation de la table au format CSV',
  'Extracted entity from file': '',
  'Extracted event from file.': '',
  'ExtractusD Response': 'Réponse ExtractusD',
  'ExtractusD request': 'Demande ExtractusD',
  'ExtractusD request by user': "Demande d'ExtractusD par l'utilisateur",
  'ExtractusD requested by user': "Demandé par l'utilisateur",
  'Eyes Color': 'Couleur des yeux',
  FACES: 'VISAGES',
  FEED: '',
  'Face & name': 'Un visage et un nom',
  Facebook: 'Facebook',
  'Facebook Friend': 'Ami(e) sur Facebook',
  'Facebook Group': 'Groupe Facebook',
  'Facebook Significant Other': 'Autre significatif de Facebook',
  'Facebook account confirmed. Uploading photos into account':
    'Compte Facebook confirmé. Téléchargement des photos sur le compte',
  'Facebook family': 'Famille Facebook',
  'Facebook friends': 'Ami(e) Facebook',
  'Facebook group member': 'Membre du groupe Facebook',
  'Facebook groups': 'Groupes Facebook',
  'Facebook id': 'Identifiant Facebook',
  'Facial Hair': 'Poils du visage',
  Failed: '',
  'Failed request': '',
  'Failed to find intel suggestions!':
    'Échec de la recherche de suggestions d’intelligence !',
  'Failed to retrieve': 'Impossible de récupérer',
  'Failed!': 'Échec !',
  'Falkland Islands': 'les îles Falkland',
  Family: 'Famille',
  'Family Members': 'Membres de la famille',
  'Family member on Facebook': 'Membre de la famille sur Facebook',
  'Family relations': 'Relations familiales',
  'Fan of': 'Fan de',
  Fans: 'Fans',
  'Faroe Islands': 'Îles Féroé',
  'Fathers name': 'Nom du père',
  'Favorite count': 'Nombre de favoris',
  'Favorite results': 'Résultats favoris',
  Feature: 'Fonctionnalité',
  Features: 'Fonctionnalités',
  February: 'février',
  'Federated States of Micronesia': 'États fédérés de Micronésie',
  Feed: 'Flux',
  Feedback: 'Commentaires',
  'Feedback is required': 'Des commentaires sont requis',
  Female: 'Femme',
  'Fenced Area': 'Zone clôturée',
  'Fetch call logs': "Récupérer les journaux d'appels",
  'Fetch instant messaging profiles':
    'Récupérer les profils de messagerie instantanée',
  'Fetch social profiles': 'Récupérer les profils sociaux',
  'Fetching data': 'Récupération de données',
  'Fetching facebook friends': 'Récupération des amis Facebook',
  'Field is required': 'Le champ est obligatoire',
  Fiji: 'Fidji',
  File: 'Fichier',
  'File error': 'Erreur de fichier',
  'File name': 'Nom du fichier',
  'File name must match #{type} number':
    'Le nom du fichier doit correspondre au numéro #{type}',
  'File size': 'Taille du fichier',
  'File upload failed. Please make sure that your file is in Zip format, and contains a properly structured JSON file':
    'File upload failed. Please make sure that your file is in Zip format, and contains a properly structured JSON file',
  'File uploaded successfully!': 'Le fichier a été téléchargé avec succès !',
  'Filling OTP': "Remplissage de l'OTP",
  'Filling personal info': 'Remplissage des informations personnelles',
  'Filling photos': 'Remplissage des photos',
  Filter: 'Filtrer',
  'Filter by device': '',
  'Filter by source type': 'Filtrer par type de source',
  'Filter panel': 'Panneau de filtre',
  'Filter target': '',
  'Filter targets': 'Filtrer les cibles',
  Filters: 'Filtres',
  'Financial crime': 'Crime financier',
  'Find commons': 'Trouver des points communs',
  'Find possible associates, common visited places, connections on social media, groups and more. Credits will be deducted only if accurate information is found.':
    'Trouvez des associés possibles, des lieux visités, des connexions sur les réseaux sociaux, des groupes et plus encore. Les crédits ne seront déduits que si des informations précises sont trouvées.',
  'Find possible associates, visited places, connections on social media, groups and more.':
    'Trouvez des associés possibles, des lieux visités, des connexions sur les réseaux sociaux, des groupes et plus encore.',
  'Find profile': 'Trouver profil',
  'Find profiles': 'Trouver profils',
  'New Search': '',
  'Find profiles & locate phone': 'Trouver profils & localiser téléphone',
  'Find profiles by': '',
  'Find profiles by face and name': 'Trouver profils par visage & nom',
  'Finish adding targets': "Terminer l'ajout d'objectifs",
  'Finish editing': 'Terminer la modification',
  Finland: 'Finlande',
  'First message': '',
  'First month is free': 'Le premier mois est gratuit',
  'First page': 'Première page',
  Fixed: 'Fixe',
  'Fixed credits assigned': 'Crédits fixes assignés',
  'Fixed credits available': 'Crédits fixes disponibles',
  'Fixed quota': 'Quota fixe',
  Fleet: 'Flotte',
  Follow: 'Suivre',
  'Follow is already disabled.': 'Le suivi est déjà désactivé.',
  'Follow is already enabled.': 'Le suivi est déjà activé.',
  'Follow started for': 'Suivi commencé pour',
  'Follow stopped for': 'Suivi arrêté pour',
  'Follow with Geofence': 'Suivre avec une géo clôture',
  Follower: 'Abonné',
  'Follower on Instagram': 'Abonné/Abonnée sur Instagram',
  'Follower on Twitter': 'Abonné/Abonnée sur Twitter',
  Followers: 'Abonnés',
  Following: 'Suivant',
  'Following MSISDN - Platform combination is already selected':
    'La combinaison suivante MSISDN - Plate-forme est déjà sélectionnée',
  'Following on Instagram': 'Abonnement sur Instagram',
  'Following on Twitter': 'Abonnement sur Twitter',
  'Following pages': 'Suivre des pages',
  Followings: 'Suivis',
  'For authorized use only': 'Réservé à un usage autorisé uniquement',
  'For example': 'Par exemple',
  'For generating OTP codes, please download the Authenticator App':
    'Pour générer des codes OTP, veuillez télécharger l’application Authenticator',
  Foreign: 'Étranger',
  Found: 'Trouvé',
  'Found for': 'Trouvé pour',
  France: 'France',
  Fraud: 'Fraude',
  'Free text': 'Texte libre',
  French: '',
  Frequency: 'Fréquence',
  'Frequently communicated with #{receiverMsisdn}':
    'A communiqué fréquemment avec #{receiverMsisdn}',
  'Frequently had consecutive communications with #{receiverMsisdn}':
    'A eu des communications consécutives fréquentes avec #{receiverMsisdn}',
  'Frequently had consecutive communications with #{receiverMsisdn} within 1-hour time intervals,':
    'A eu des communications consécutives fréquentes avec #{receiverMsisdn} dans des intervalles de temps d’1 heure',
  Friday: 'Vendredi',
  Friend: 'Ami',
  'Friend relations': 'Relations d’amitié',
  Friends: 'Amis',
  From: 'De',
  'From - To': 'De - À',
  'From Date': 'À partir de la date',
  'Full Name': 'Nom complet',
  'Full Post': 'Publication complète',
  'Full name': 'Nom complet',
  'Full report': 'Rapport complet',
  'Full screen': 'Plein écran',
  'GEO query': 'Requête GEO',
  GO: 'ALLER',
  Gabon: 'Gabon',
  Gambia: 'Gambie',
  'Gather more intel from MSISDN, email & social profiles':
    "Obtenez plus d'informations sur les MSISDN, les e-mails et les profils sociaux.",
  'Gathering activity feed details':
    "Collecte des détails de l'historique d'activité",
  'Gathering activity patterns': 'Collecte des modèles d’activité',
  'Gathering call analysis': 'Collecte de l’analyse des appels',
  'Gathering call log common associates statistics':
    'Collecte des statistiques des contacts communs dans le journal des appels',
  'Gathering call log entry': "Collecte de l'entrée du journal d'appels",
  'Gathering call log statistics':
    "Rassemblement de statistiques de journal d'appels",
  'Gathering call log top associates statistics':
    "Collecte des statistiques sur les principaux associés du journal d'appels",
  'Gathering call logs report for':
    "Rapport de journaux d'appels en cours pour",
  'Gathering common locations': 'Collecte des emplacements communs',
  'Gathering count analysis': 'Collecte de l’analyse du décompte',
  'Gathering data for target details':
    'Rassemblement de données pour les détails de la cible',
  'Gathering details for targets': 'Collecte des détails pour les cibles',
  'Gathering family relation': 'Rassemblement de la relation familiale',
  'Gathering images': "Rassemblement d'images",
  'Gathering locations': 'Collecte des emplacements',
  'Gathering social network details': 'Collecte des détails du réseau social',
  'Gathering social network top associates':
    'Rassemblement des principaux associés du réseau social',
  'Gathering social word details': 'Rassemblement des détails de mots sociaux',
  'Gathering target summary report for':
    'Rassemblement du rapport de résumé de la cible pour',
  'Gathering top locations': 'Collecte des lieux les plus visités',
  Gender: 'Genre',
  Generate: 'Créa',
  'Generate pdf': 'Générer un PDF',
  'Generating call logs report data': '',
  'Generating map': 'Génération de la carte',
  'Generating virtual ID': "Génération d'identifiant virtuel",
  'Geo AoI': "Zone d'intérêt géo",
  'Geo Country': 'Pays géo',
  'Geo Follow': 'Suivi géographique',
  'Geo Political Entities': 'Entités géopolitiques',
  'Geo Query': 'Requête géo',
  Geofencing: 'Géorepérage',
  'Geofencing canceled!': 'Géolocalisation annulée !',
  'Geoloc Knowledge Base': 'Base de connaissances de géolocalisation',
  Geolocate: 'Géolocaliser',
  'Geolocate phone': 'Localiser téléphone',
  Geolocation: 'Géolocalisation',
  'Geolocation details': 'Détails de géolocalisation',
  'Geolocation query successfully submitted':
    'La requête de géolocalisation a été soumise avec succès',
  'Geolocation scheduler will start after radical speech detection!':
    'La planification de la géolocalisation démarrera après la détection de discours radical !',
  Geolocations: 'Géolocalisations',
  'Geographic resolution': '',
  Georgia: 'Géorgie',
  Germany: 'Allemagne',
  'Get Cell Level Location': 'Obtenir la localisation de la tour',
  'Get Facebook Posts': 'Obtenir des publications Facebook',
  'Get Facebook Relations': 'Obtenir des relations Facebook',
  'Get Photo Info': 'Obtenir les informations de la photo',
  'Get activity alerts': 'Get activity alerts',
  'Get alerts for new posts by the target on social media': '',
  'Get current location of this MSISDN':
    'Obtenez l’emplacement actuel de ce MSISDN',
  'Get historical data': 'Obtenir les données historiques',
  'Get historical locations': 'Obtenez les emplacements historiques',
  'Get information': 'Obtenir des informations',
  'Get information & geolocate': 'Obtenir des informations et géolocaliser',
  'Get sim': 'Obtenir une carte SIM',
  'Get the CallerID': 'Obtenir le CallerID',
  'Get the CallerID (4 credits)': 'Obtenir le CallerID (4 crédits)',
  'Get the Neighbour Cells': 'Obtenir les cellules voisines',
  'Get the Neighbour Cells (1 credit)':
    'Obtenir les cellules voisines (1 crédit)',
  'Getting OTP': "Obtention de l'OTP",
  'Getting cookies': 'Obtention des cookies',
  Ghana: 'Ghana',
  'GiO - GeoLocation Services - For exclusive usage by government law enforcement agency: Government of the State of Nuevo Leon - Backed up by EUC - 2017':
    "GiO - Services de géolocalisation - À usage exclusif des agences d'application de la loi du gouvernement : gouvernement de l'État de Nuevo León - Appuyé par EUC - 2017",
  Gibraltar: 'Gibraltar',
  Go: 'Aller',
  'Go to call logs page': 'Aller à la page des journaux d’appels',
  'Go to target page': 'Aller à la page cible',
  'Got it': 'Compris',
  Graph: 'Graphique',
  'Graph settings': 'Paramètres du graphique',
  Greece: 'Grèce',
  Greenland: 'Groenland',
  Grenada: 'Grenade',
  Group: 'Groupe',
  'Group By': 'Regrouper par',
  'Group ID': 'ID de groupe',
  'Group Member on Facebook': 'Membre du groupe sur Facebook',
  'Group Name': 'Nom du groupe',
  'Group created successfully!': 'Groupe créé avec succès !',
  'Group details': 'Détails du groupe',
  'Group edited successfully!': 'Groupe modifié avec succès !',
  'Group has been successfully deleted': 'Le groupe a été supprimé avec succès',
  'Group has not been created': "Le groupe n'a pas été créé.",
  'Group has not been deleted': "Le groupe n'a pas été supprimé.",
  'Group has not been edited': "Le groupe n'a pas été modifié.",
  'Group members': 'Membres du groupe',
  'Group messages': '',
  'Group results': 'Regrouper les résultats',
  Groups: 'Groupes',
  Guatemala: 'Guatemala',
  Guernsey: 'Guernesey',
  Guinea: 'Guinée',
  'Guinea-Bissau': 'Guinée-Bissau',
  Guyana: 'Guyane',
  'Hair Color': 'Couleur de cheveux',
  Haiti: 'Haïti',
  'Has Expired Avatar': 'Possède avatar expiré',
  Hashtag: 'Hashtag',
  'Hashtag added successfully!': 'Hashtag ajouté avec succès !',
  'Hashtag has not been added': "Le hashtag n'a pas été ajouté",
  Hashtags: 'Hashtags',
  Hearts: 'Cœurs',
  Heatmap: 'Carte thermique',
  Height: 'Taille',
  'Hide archived': 'Masquer les archives',
  'Hide descriptions': 'Masquer les descriptions',
  'Hide nearby Telegram users': 'Masquer les utilisateurs Telegram à proximité',
  'Hide options': 'Masquer les options',
  'Hide query history': "Masquer l'historique des requêtes",
  'Hide statistics': 'Masquer les statistiques',
  Hierarchy: 'Hiérarchie',
  'High activity': 'Haute activité',
  'Highlight nodes based on number and type of direct connections':
    'Surligner les nœuds en fonction du nombre et du type de connexions directes',
  'Highlight nodes that act as "bridges" between the connected network':
    'Surligner les nœuds qui agissent comme des "ponts" entre le réseau connecté',
  History: 'Historique',
  Home: 'Accueil',
  'Home Location': 'Domicile',
  'Home last sale date': 'Dernière date de vente de la maison',
  Hometown: 'Ville natale',
  Hometownn: '',
  Homicide: 'Homicide',
  Honduras: 'Honduras',
  Hour: 'Heure',
  'Hour of day': 'Heure du jour',
  'House number': 'Numéro de maison',
  Hungary: 'Hongrie',
  ID: 'ID',
  IFE: 'IFE',
  IMEI: 'IMEI',
  'IMEI(s)': 'IMEI(s)',
  IMSI: 'IMSI',
  'IMSI(s)': 'IMSI(s)',
  IMSIs: 'IMSI',
  INFO: 'INFO',
  IP: 'IP',
  'IP found': 'IP trouvé',
  'IP Location': 'Localisation IP',
  'IP address': '',
  'IP addresses': 'Adresses IP',
  'IP/Carrier': 'IP/Opérateur',
  'Find the area of the IP address': '',
  'Get location of the IP': '',
  'This method requires calling the device via instant messaging app, and as a result the person might become suspicious.':
    '',
  'Are you sure you want to continue?': '',
  Request: '',
  IPs: 'IP',
  Iceland: 'Islande',
  Id: 'Identifiant',
  'Identification card number': 'Numéro de carte d’identité',
  'Identifying top profiles': 'Identification des profils les plus populaires',
  Idle: 'Inactif',
  'If the status is N/A, yet you will have the option to enable the BOT to continue attempts.':
    "Si l'état est N/A, vous aurez toutefois la possibilité d'activer le BOT pour continuer les tentatives.",
  'If you dont associated the correct MSISDN with platform account, the request could take up to 144 hours.':
    "Si vous n'avez pas associé le MSISDN correct à votre compte de plateforme, la demande pourrait prendre jusqu'à 144 heures.",
  'If you edit or delete the MSISDN the Call Log for this target will be removed':
    "Si vous modifiez ou supprimez le MSISDN, le journal d'appels pour cette cible sera supprimé.",
  'If you lost your device please reset the 2-Step Verification by pressing the reset button. An email will be sent to guide you to add a new device.':
    "Si vous avez perdu votre appareil, réinitialisez la vérification en deux étapes en appuyant sur le bouton de réinitialisation. Un e-mail sera envoyé pour vous guider dans l'ajout d'un nouvel appareil.",
  Image: 'Image',
  'Image Analysis': "Analyse d'image",
  'Image attached': 'Image jointe',
  Images: 'Images',
  Impersonation: 'Impersonation',
  Import: 'Importer',
  'Import Historical Locations': 'Importation d’emplacements historiques',
  'Import Virtual ID by credentials': '',
  'Import files': '',
  Imported: 'Importé',
  imported: 'importé',
  'Imported date': 'Imported date',
  'Imported successfully': 'Importation réussie',
  Importing: '',
  'Importing data': 'Importation des données',
  'Importing historical data': 'Importation de données historiques',
  'Imsi query': 'Requête IMSI',
  'In SMS': 'SMS entrant',
  'In call': 'Appel entrant',
  'In order to strengthen account security, Two Factor Authentication (2FA) is mandatory for all users.':
    "Pour renforcer la sécurité du compte, l'authentification à deux facteurs (2FA) est obligatoire pour tous les utilisateurs.",
  'In progress': 'En cours',
  Incoming: 'Entrant',
  'Incoming SMS': 'SMS entrants',
  'Incoming and Outgoing': 'Entrants et sortants',
  'Incoming call': 'Appel entrant',
  'Incoming sms': 'SMS entrant',
  India: 'Inde',
  Individual: 'Individuel',
  Indonesia: 'Indonésie',
  Indonesian: '',
  Industries: 'Industries',
  Industry: 'Industrie',
  'Inform Security Personel': 'Informer le personnel de sécurité',
  'Information available': '',
  'Infrastructure and buildings': 'Infrastructure et bâtiments',
  'Initialising search for': '',
  'Insert IMSI': 'Insérer IMSI',
  'Insert MSISDN': 'Insérer MSISDN',
  'Insert a single or multiple MSISDNs or IMSIs separated by comma':
    'Insérez un ou plusieurs MSISDN ou IMSI séparés par des virgules',
  'Insert phones': 'Insérer des téléphones',
  Inserted: 'Insérés',
  'Inside of area': "À l'intérieur de la zone",
  'Inside the circle': "À l'intérieur du cercle",
  'Insights about most common and top associates':
    'Statistiques sur les contacts principaux et les plus courants',
  Instagram: 'Instagram',
  'Instagram handle': 'Identifiant Instagram',
  'Instant messaging': 'Messagerie instantanée',
  'Related instant messaging apps': '',
  Institution: 'Institution',
  'Intel Query': 'Requête Intel',
  'Intel Search Results': 'Résultats de la recherche de renseignements',
  'Intel search': "Recherche d'intelligence",
  'Intellectus Knowledge Base': 'Base de connaissances Intellectus',
  Intelligence: 'Intelligence',
  'Intelligence Query': "Requête d'intelligence",
  'Intelligence is not available yet':
    "L'intelligence n'est pas encore disponible",
  'Intelligence query': "Requête d'intelligence",
  'Intelligence search': 'Recherche de renseignements',
  'Intelligence suggestions': 'Suggestions de renseignements',
  'Interacted with': 'A interagi avec',
  'Interactions/ Relations': '',
  'Interested in': 'Intéressé par',
  Invalid: 'Invalide',
  'Invalid IMEI provided': 'IMEI fourni invalide',
  'Invalid MSISDN': 'MSISDN invalide',
  'Invalid OTP code provided': 'Code OTP invalide fourni',
  'Invalid URL. Social network not supported':
    'URL invalide. Réseau social non pris en charge',
  'Invalid credentials provided': 'Identifiants invalides fournis',
  'Invalid email address': 'Adresse email invalide',
  'Invalid file format provided.': 'Format de fichier non valide fourni.',
  'Invalid location': 'Emplacement invalide',
  'Invalid relationship type': 'Type de relation invalide',
  'Invalid type - allowed only png, jpg, jpeg':
    'Type invalide - seuls les formats png, jpg, jpeg sont autorisés',
  'Invalid values. Please fill in all fields.':
    'Valeurs invalides. Veuillez remplir tous les champs.',
  'Invalid values. Please try again.': 'Valeurs invalides. Veuillez réessayer.',
  'Invalid values. Please try again. Username must contain at least one letter.':
    "Valeurs invalides. Veuillez réessayer. Le nom d'utilisateur doit contenir au moins une lettre.",
  Investigate: '',
  'Investigate lead': 'Investiguer la piste',
  Investigation: 'Enquête',
  'Investigation filters': '',
  Investigations: 'Enquêtes',
  Iran: "L'Iran",
  Iraq: 'Irak',
  Ireland: 'Irlande',
  'Is dead': 'Est décédé',
  'Is followed by': 'Est suivi(e) par',
  'Is following': 'Suit',
  'Is/was working as': 'Travaille/travaillait en tant que',
  'Isle of Man': 'île de Man',
  Israel: 'Israël',
  Italy: 'Italie',
  'Item matched': 'Élément correspondant',
  Items: 'Articles',
  'Items per page': 'Éléments par page',
  Jamaica: 'Jamaïque',
  January: 'janvier',
  Japan: 'Japon',
  Jersey: 'Jersey',
  Job: 'Emploi',
  'Job(s)': 'Emploi(s)',
  Jobs: 'Emplois',
  'Join group': 'Rejoindre le groupe',
  'Join random or specific communities on the selected social network.':
    'Rejoindre des communautés aléatoires ou spécifiques sur le réseau social sélectionné.',
  'Joined At': 'Inscrit le',
  'Joined group': 'Groupe rejoint',
  'Joining groups': 'Rejoindre des groupes',
  Jordan: 'Jordan',
  July: 'juillet',
  June: 'juin',
  Kazakhstan: 'Kazakhstan',
  'Keep in mind that you can make changes later if needed.': '',
  Kenya: 'Kenya',
  Keyword: 'Mot-clé',
  'Keyword lists': 'Listes de mots-clés',
  Keywords: 'Mots-clés',
  'Keywords list': 'Liste de mots-clés',
  'Keywords monitored': 'Mots clés surveillés',
  Kidnapping: 'Enlèvement',
  Kiribati: 'Kiribati',
  'Knowledge Base': 'Base de connaissances',
  'Knowledge base': 'Base de connaissances',
  Kosovo: 'Kosovo',
  Kuwait: 'Koweit',
  Kyrgyzstan: 'Kirghizistan',
  LABELS: 'ÉTIQUETTES',
  LAC: 'LAC',
  'LAC found': 'LAC trouvée',
  'Location Area Code': '',
  LANGUAGE: 'LANGUE',
  LOCATION: 'Localisation',
  'LOG IN': 'CONNEXION',
  Labels: 'Étiquettes',
  'Landline(s)': 'Ligne(s) fixe(s)',
  Landlines: 'Lignes fixes',
  Language: 'Langue',
  Languages: 'Langues',
  Laos: 'Laos',
  'Last 24 hours': 'Dernières 24 heures',
  'Last 30 days': 'Les 30 derniers jours',
  'Last 7 days': 'Les 7 derniers jours',
  'Last 90 days': 'Les 90 derniers jours',
  'Last Activity': 'Dernière activité',
  'Last Hour': 'Dernière heure',
  'Last Modified': 'Dernière modification',
  'Last Seen': 'Dernière apparition',
  'Last activity': 'Dernière activité',
  'Last call log location': "Emplacement du dernier journal d'appel",
  'Last hour': 'Dernière heure',
  'Last known activity': '',
  'Last known location': 'Dernière position connue',
  'Last message': '',
  'Last located': '',
  'Last modifications': 'Dernières modifications',
  'Last modified': 'Dernière modification',
  'Last name': 'Nom de famille',
  'Last page': 'Dernière page',
  'Last query': 'Dernière requête',
  'Last seen': 'Vu pour la dernière fois',
  'Last seen online': 'Dernière connexion en ligne',
  'Last week': 'Semaine dernière',
  Lat: 'Latitude',
  'Lat / Lon': 'Lat / Lon',
  'Lat/Lon': 'Lat/Lon',
  Latest: '',
  'Latest Location': 'Dernière localisation',
  'Latest activity': '',
  'Latest search': '',
  'Latest search date': '',
  'Latest Search Date': '',
  'Latest status': '',
  'Latest query': '',
  Latitude: 'Latitude',
  Latvia: 'Lettonie',
  Law: 'Loi',
  Layout: 'Disposition',
  Lebanon: 'Liban',
  Ledger: 'Grand livre',
  Lens: 'Objectif',
  Lesotho: 'Lesotho',
  Level: 'Niveau',
  List: '',
  'List of searches': '',
  'List of searches for': '',
  Liberia: 'Libéria',
  Libya: 'Libye',
  'License Plate': "Plaque d'immatriculation",
  'License Plate(s)': "Plaque(s) d'immatriculation",
  'License Plates': "Plaques d'immatriculation",
  Liechtenstein: 'Liechtenstein',
  'Like group posts': 'Aimer les publications de groupe',
  'Like posts': 'Aimer les publications',
  Liked: 'Aimé',
  Likers: "J'aime",
  'Liking posts': 'Appréciation des publications',
  'Lines parsed': 'Lignes analysées',
  Link: 'Lien',
  'Link Analysis': 'Analyse de liens',
  'Link analysis': 'Analyse des liens',
  'Link analysis v2': 'Analyse des liens v2',
  Linkedin: 'Linkedin',
  'Linkedin Employee': 'Employé de LinkedIn',
  Links: 'Liens',
  'List View': 'Vue de la liste',
  'List name': 'Nom de la liste',
  'List of all cases': 'Liste de tous les cas',
  'List of all imported call detail records':
    'Liste de tous les enregistrements de détails d’appels importés',
  'List of all targets': 'Liste de toutes les cibles',
  'List of imeis': 'Liste des IMEI',
  'List of imsis': 'Liste des IMSI',
  'List of msisdns': 'Liste des MSISDN',
  'List of periods': 'Liste des périodes',
  Lithuania: 'Lituanie',
  'Live Session': 'Session en direct',
  'Live Session has been successfully obtained':
    'La session en direct a été obtenue avec succès',
  'Live data': 'Données en direct',
  'Live monitoring': 'Surveillance en temps réel',
  'Live monitoring started at': 'La surveillance en direct a commencé le',
  'Live session request created successfully!':
    'Demande de session en direct créée avec succès !',
  'Live tasks': 'Tâches en cours',
  'Load a case': 'Charger un cas',
  'Load a target': 'Charger une cible',
  'Load selected data': '',
  Loading: 'Chargement',
  'Loading call log data': "Chargement des données du journal d'appels",
  'Loading data': 'Chargement des données',
  'Loading target details': "Chargement des détails de l'objectif",
  'Loading the file could not be completed.': '',
  Locate: 'Localiser',
  'Locate a single MSISDN or IMSI': 'Localiser un seul MSISDN ou IMSI',
  'Locate all': 'Localiser tous',
  'Locate now': 'Localiser maintenant',
  'Locate phone': 'Localiser téléphone',
  Located: 'Localisés',
  'Located at': 'Localisé à',
  Locating: 'Localisation en cours',
  Location: 'Emplacement',
  'Location copied!': '',
  'Location Heatmap': 'Carte thermique de localisation',
  'Location History Found': 'Historique de localisation trouvé',
  'Location History Not Found': 'Historique de localisation introuvable',
  'Location History Request': "Demande d'historique de localisation",
  'Location Info': 'Informations de localisation',
  'Locate Now': '',
  'Location Probalility': 'Probabilité de localisation',
  'Location Sources': 'Sources de localisation',
  'Location Timeline': 'Chronologie de localisation',
  'Location belongs to the destination':
    "L'emplacement appartient à la destination",
  'Location belongs to the source': "L'emplacement appartient à la source",
  'Location found': 'Localisation trouvée',
  'Location history': 'Historique de localisation',
  'Location log': 'Journal de localisation',
  'Location probability': 'Probabilité de localisation',
  'Location sources': 'Sources de localisation',
  Locations: 'Emplacements',
  LOCATIONS: 'EMPLACEMENTS',
  'Locations only': '',
  Lock: 'Verrouiller',
  'Lock Temporary User': "Verrouiller l'utilisateur temporaire",
  'Lock user': "Verrouiller l'utilisateur",
  Log: 'Journal',
  'Log on': 'Connexion',
  'Log on task': 'Se connecter à une tâche',
  'Logged in': '',
  'Logging in': 'Connexion en cours',
  Login: 'Connexion',
  'Login Now': 'Connectez-vous maintenant',
  'Login status': '',
  Logon: 'Connexion',
  Logout: 'Déconnexion',
  Lon: 'Longitude',
  Longest: 'La plus longue',
  'Longest call': 'Appel le plus long',
  Longitude: 'Longitude',
  'Look for a country, city or address':
    'Recherchez un pays, une ville ou une adresse',
  'Low activity': 'Faible activité',
  Luxembourg: 'Luxembourg',
  MAPS: 'CARTES',
  MCC: 'MCC',
  METADATA: 'MÉTADONNÉES',
  MNC: 'MNC',
  MNOs: 'Opérateurs mobiles',
  'MOBILE NETWORK': 'Réseau mobile',
  MSISDN: 'MSISDN',
  'MSISDN is connected with this instant messaging profile':
    'Le MSISDN est connecté à ce profil de messagerie instantanée',
  'MSISDN is not linked with a WhatsApp account':
    "Le MSISDN n'est pas lié à un compte WhatsApp",
  'MSISDN is not linked with a whatsApp account': '',
  'MSISDN(s)': 'MSISDN(s)',
  MSISDNs: 'Numéros de téléphone',
  Macedonia: 'Macédoine',
  Madagascar: 'Madagascar',
  Major: 'Majeur',
  'Make sure that the file is in Zip format and contains a JSON file':
    'Make sure that the file is in Zip format and contains a JSON file',
  Malawi: 'Malawi',
  Malaysia: 'Malaisie',
  Maldives: 'Maldives',
  Male: 'Male',
  Mali: 'Mali',
  Malta: 'Malte',
  Manage: 'Gérer',
  'Management for a new case is free of charge for #{days} days':
    "La gestion d'un nouveau cas est gratuite pendant #{days} jours.",
  'Management for a new target is free of charge for #{days} days':
    "La gestion d'une nouvelle cible est gratuite pendant #{days} jours.",
  Manager: 'Gérer',
  Map: 'Carte',
  'Map View': 'Vue de la carte',
  'Map circle': 'Cercle de la carte',
  'Map default countries': 'Pays par défaut sur la carte',
  Maps: 'Cartes',
  March: 'mars',
  'Marital Status': 'État civil',
  'Mark all as read': 'Tout marquer comme lu',
  'Marshall Islands': 'Iles Marshall',
  Mauritania: 'Mauritanie',
  Mauritius: 'Maurice',
  Maximize: 'Agrandir',
  'Maximize map': 'Agrandir la carte',
  Maximum: 'Maximum',
  'Maximum allowed size:': 'Taille maximale autorisée :',
  'Maximum amount of robot queries is':
    'Le nombre maximal de requêtes robotiques est de',
  'Maximum credits assigned': 'Crédits maximaux assignés',
  'Maximum credits available': 'Crédits maximaux disponibles',
  'Maximum image size is 2MB.': "La taille maximale de l'image est de 2 Mo.",
  'Maximum image size is 2MB. Please choose another image.':
    "La taille maximale de l'image est de 2 Mo. Veuillez choisir une autre image.",
  'Maximum number of targets has been reached':
    'Vous avez atteint le nombre maximum de cibles',
  'Maximum quota': 'Quota maximum',
  'Maximum quota assigned': 'Quota maximum assigné',
  'Max limit reached': '',
  May: 'mai',
  Media: 'Média',
  'Medical condition': 'État de santé',
  Medium: 'Moyen',
  'Meeting Points': 'Points de rencontre',
  'Meeting points': 'Points de rencontre',
  Members: 'Membres',
  Mentions: 'Mentions',
  Message: 'Message',
  'Message to': '',
  Messages: '',
  Method: 'Méthode',
  Mexico: 'Mexique',
  'Middle name': 'Deuxième nom',
  Minimize: 'Réduire',
  'Minimize map': 'Réduire la carte',
  Minor: 'Mineur',
  Miscellaneous: 'Divers',
  'Miscellaneous ': 'Divers',
  'Missing CDRs for this target': 'CDRs manquants pour cette cible',
  'Missing file to upload': '',
  'Mission is empty. Click the': '',
  'Mobile Data': '',
  'Mobile Network': 'Réseau mobile',
  'Mobile network': 'Réseau mobile',
  'Mobile Operator': '',
  Model: 'Modèle',
  Moldova: 'Moldavie',
  Monaco: 'Monaco',
  Monday: 'Lundi',
  Money: 'Argent',
  Mongolia: 'Mongolie',
  'Monitor changes': 'Surveiller les modifications',
  Monitoring: 'Surveillance',
  'Monitoring is': 'La surveillance est',
  Montenegro: 'Monténégro',
  'Month / Year first': '',
  'Month of year': 'Mois de l’année',
  'Monthly credits assigned': 'Crédits mensuels assignés',
  'Monthly credits available': 'Crédits mensuels disponibles',
  'Monthly quota': 'Quota mensuel',
  'Monthly quota assigned': 'Quota mensuel assigné',
  Montserrat: 'Montserrat',
  More: 'Plus',
  'More filters': 'Plus de filtres',
  'More actions for': '',
  Morocco: 'Maroc',
  'Most Active': 'Les plus actifs',
  'Most Common Relations': 'Relations les plus courantes',
  'Most active period': 'Période la plus active',
  'Most common associates': 'Associés les plus courants',
  'Most common faces': 'Visages les plus courants',
  'Most inactive period': 'Période la moins active',
  'Most recent': 'Le plus récent',
  'Most relevant': 'Le plus pertinent',
  'Mostly active': 'Le plus actif',
  'Mostly inactive': 'Le plus inactif',
  'Mothers name': 'Nom de famille de la mère',
  Mozambique: 'Mozambique',
  Multiple: 'Multiple',
  'Multiple cases': 'Cas multiples',
  'Multiple profile searches': '',
  'Must be at least 1 character long and maximum characters allowed are 255, special charactes and preceding or following spaces are not allowed':
    'Doit contenir au moins 1 caractère et le maximum autorisé est de 255 caractères, les caractères spéciaux ainsi que les espaces précédents ou suivants ne sont pas autorisés',
  'Must be at least 10 characters long and special characters are not allowed':
    'Doit contenir au moins 10 caractères et les caractères spéciaux ne sont pas autorisés',
  'Must be at least 3 characters long and maximum characters allowed are 15, special charactes are not allowed':
    'Doit comporter au moins 3 caractères et au maximum 15 caractères, les caractères spéciaux ne sont pas autorisés',
  'Must be at least 3 characters long and maximum characters allowed are 20, special charactes and preceding or following spaces are not allowed':
    "La longueur doit être d'au moins 3 caractères et au maximum de 20 caractères, les caractères spéciaux et les espaces précédents ou suivants ne sont pas autorisés",
  'Must be at least 3 characters long, special charactes and preceding or following spaces are not allowed':
    'Doit comporter au moins 3 caractères, les caractères spéciaux ainsi que les espaces avant ou après ne sont pas autorisés.',
  Muted: 'Sourdine',
  'Mutual Facebook Friends': 'Amis Facebook en commun',
  'My details': 'Mes informations',
  'My places': 'Mes lieux',
  'My target': 'Ma cible',
  Myanmar: 'Birmanie',
  NIK: 'NIK',
  'N/A': 'N/A',
  Name: 'Nom',
  'Name Middle Surname': 'Nom Second Nom',
  'Name Surname': 'Nom Prénom',
  'Name cannot be just whitespaces':
    "Le nom ne peut pas être composé uniquement d'espaces blancs",
  'Name is required': 'Le nom est requis',
  'Name must contain at least 3 characters':
    'Le nom doit contenir au moins 3 caractères',
  'Name of the area': 'Nom de la zone',
  'Name(s)': 'Nom(s)',
  Names: 'Noms',
  Namibia: 'Namibie',
  Nationalities: 'Nationalités',
  Nauru: 'Nauru',
  'Nearest Neighbors': 'Voisins les plus proches',
  Negative: 'Négatif',
  'Neighbour cell towers with params':
    'Tours de cellules voisines avec des paramètres',
  Nepal: 'Népal',
  Neqative: 'Négatif',
  Network: 'Réseau',
  'Network(s)': 'Réseau(s)',
  'Network N/A': 'Réseau N/A',
  'Never located': '',
  Neuter: 'Neutre',
  Neutral: 'Neutre',
  'New Credits Usage Statistics Dashboard':
    'Nouveau tableau de bord des statistiques d’utilisation des crédits',
  'New Discovery': '',
  'New MSISDN': 'Nouveau MSISDN',
  'New Password': 'Nouveau mot de passe',
  'New Query': 'Nouvelle requête',
  'New Zealand': 'Nouvelle-Zélande',
  'New call log': 'Nouveau journal d’appels',
  'New case': 'Nouveau cas',
  'New entity': 'Nouvelle entité',
  'New intel query': "Nouvelle requête d'intelligence",
  'New investigation': 'Nouvelle enquête',
  'New note text': 'Nouveau texte de note',
  'New password': 'Nouveau mot de passe',
  'New post': '',
  'New query': 'Nouvelle requête',
  'New results available': '',
  'New target': 'Nouvelle cible',
  'New user #{username} created successfully!':
    'Nouvel utilisateur #{username} créé avec succès !',
  'New virtual ID': 'Nouvel identifiant virtuel',
  'New virtual ID ': 'Nouvel identifiant virtuel',
  Next: 'Suivant',
  'Next page': 'Page suivante',
  'Next step': 'Étape suivante',
  Nicaragua: 'Nicaragua',
  Niger: 'Niger',
  Nigeria: 'Nigeria',
  Niue: 'Niué',
  No: 'Non',
  'No crypto transactions': '',
  'No Data': 'Pas de données',
  'No Data Available': 'Pas de données disponibles',
  'No Location found for trilateration query!':
    'Aucune localisation trouvée pour la requête de trilatération !',
  'No Results': 'Pas de résultats',
  'No records found': '',
  'No activity': 'Aucune activité',
  'No activity log for this avatar': 'Aucun journal d’activité pour cet avatar',
  'No archived recommandations available':
    'Aucune recommandation archivée disponible',
  'No area is defined on the map.': "Aucune zone n'est définie sur la carte.",
  'No available browsers. Please retry':
    'Aucun navigateur disponible. Veuillez réessayer',
  'No available data': 'Pas de données disponibles',
  'No available data for this section yet': '',
  'No call log found for this target. Please use the menu to request or upload a call log.':
    "Aucun journal d'appels trouvé pour cette cible. Veuillez utiliser le menu pour demander ou télécharger un journal d'appels.",
  'No cases defined': 'Aucun cas défini',
  'No conversations': 'Pas de conversations',
  'No credits': 'Pas de crédits',
  'No credits assigned': 'Aucun crédit attribué',
  'No data': 'Aucune donnée',
  'No data available': 'Pas de données disponibles',
  'No data yet. Import your files or run a Somedus query for Telegram to see results.':
    '',
  'No data. Make sure you have seeds and click "Enhance profile"':
    'Aucune donnée. Assurez-vous d\'avoir des données initiales et cliquez sur "Améliorer le profil"',
  'No date available': 'Aucune date disponible',
  'No date provided': 'Aucune date fournie',
  'No entries': 'Aucune entrée',
  'No groups': 'Aucun groupe',
  'No image available': 'Aucune image disponible',
  'No items added': 'Aucun élément ajouté',
  'No keyword lists': 'Aucune liste de mots-clés',
  'No keyword lists created': 'Aucune liste de mots-clés créée',
  'No keywords': 'Aucun mot-clé',
  'No lists selected': 'Aucune liste sélectionnée',
  'No location': 'Aucune localisation',
  'No location data': 'Aucune donnée de localisation',
  'No location found': 'Aucun Emplacement Trouvé',
  'No location found for Telegram users!':
    'Aucun emplacement trouvé pour les utilisateurs Telegram !',
  'No locations found for the target <br> Add seeds to retrieve more information':
    "Aucun emplacement trouvé pour la cible <br> Ajouter des sources pour récupérer plus d'informations",
  'No locations found for the target <br> Click "#{Request call log}" to retrieve locations':
    '',
  'No locations found for the target <br> Click "Locate" or "#{Request call log}" to retrieve locations':
    'Aucun emplacement trouvé pour la cible <br> Cliquer sur "Localiser" ou "#{Request call log}" pour récupérer les emplacements',
  'No locations were found for': "Aucun lieu n'a été trouvé pour",
  'No matches found': 'Aucune correspondance trouvée',
  'No more than 1024 characters long': 'Pas plus de 1024 caractères',
  'No more than 255 characters long': 'Pas plus de 255 caractères',
  'No new suggestions available': 'Aucune nouvelle suggestion disponible',
  'No phone links defined': 'Aucun lien téléphonique défini',
  'No places': 'Aucun lieu',
  'No platforms were found. Please select the platform from the list bellow.':
    'Aucune plateforme n’a été trouvée. Veuillez sélectionner la plateforme dans la liste ci-dessous.',
  'No queries': 'Aucune requête',
  'No recommandations available': 'Aucune recommandation disponible',
  'No results found': 'Aucun résultat trouvé',
  'No seeds were found. The ExtractusD will fetch information related to Target MSISDN(s)':
    "Aucune graine n'a été trouvée. ExtractusD récupérera des informations relatives à la (aux) MSISDN cible(s)",
  'No selected list': 'Aucune liste sélectionnée',
  'No social data for current targets!':
    'Aucune donnée sociale pour les cibles actuelles !',
  'No statistics available for the selected period.':
    'Aucune statistique disponible pour la période sélectionnée.',
  'No suggestions available': 'Aucune suggestion disponible',
  'No targets': 'Aucune cible',
  'No targets added.': 'Aucune cible ajoutée.',
  'No targets defined': 'Aucun objectif défini',
  'No targets selected!': 'Aucune cible sélectionnée !',
  'No transactions found': 'Aucune transaction trouvée',
  'No transactions were made in the selected dates':
    "Aucune transaction n'a été effectuée aux dates sélectionnées",
  'No valid MSISDN': 'MSISDN invalide',
  'No virtual ID match your criteria':
    'Aucun identifiant virtuel ne correspond à vos critères',
  'No, I want to continue': 'Non, je veux continuer',
  'No, remove address from account': "Non, supprimer l'adresse du compte",
  'No, thank you': 'Non, merci',
  'No. of calls': "Nombre d'appels",
  'No. of wallets': '',
  Nodes: 'Nœuds',
  Non: 'Non',
  'Non Geo Political Entities': 'Entités non géopolitiques',
  'Non expiring': 'Non expirant',
  None: 'Aucun',
  'North Korea': 'Corée du Nord',
  Norway: 'Norvège',
  'Not Located': 'Non localisés',
  'Not active': 'Non actif',
  'Not available at the moment': '',
  'Not enough credits': 'Pas assez de crédits',
  'Not enough credits for this request':
    'Crédits insuffisants pour cette demande',
  'Not enough geolocation data or call logs data to predict locations. Would you like to create a schedule?':
    "Pas suffisamment de données de géolocalisation ou de journaux d'appels pour prédire les emplacements. Souhaitez-vous créer un planning ?",
  'Not enough information': "Pas suffisamment d'informations",
  'Not now': 'Pas maintenant',
  'Not available': '',
  Note: 'Note',
  'Note changed': 'Note modifiée',
  'Note created successfully!': 'Note créée avec succès !',
  'Note has not been created': "La note n'a pas été créée",
  'Note was added': 'Note ajoutée',
  'Note: Please leave input box empty to remove current hashtag':
    'Note : Veuillez laisser la case de saisie vide pour supprimer le hashtag actuel',
  'Note: Time periods are not available for Upload call logs ':
    'Note : Les périodes de temps ne sont pas disponibles pour le téléchargement des journaux d’appels',
  Notes: 'Notes',
  Notifications: 'Notifications',
  'Notify me on Updates': 'Me notifier lors des mises à jour',
  'Notify me when there is a': '',
  November: 'novembre',
  'Number most contacted': 'Numéro le plus contacté',
  'Number most received': 'Numéro le plus reçu',
  'Number of sessions': 'Nombre de sessions',
  Numerical: 'Numériques',
  OFF: 'Éteint',
  OFFLINE: 'HORS LIGNE',
  OK: 'OK',
  ON: 'Allumé',
  ONLINE: 'EN LIGNE',
  OPTIONS: 'OPTIONS',
  OSINT: 'OSINT',
  'OSINT completed successfully': 'OSINT terminé avec succès',
  OTP: 'OTP',
  'OTP - One Time Password': 'OTP - Mot de passe à usage unique',
  'One Time Password': 'Mot de passe à usage unique',
  'OTP Channel': 'Canal OTP',
  'OTP code': 'Code OTP',
  'OTP configuration completed': 'Configuration OTP terminée',
  'OTP required': '',
  'OVERVIEW ': '',
  Object: '',
  October: 'octobre',
  Okay: "D'accord",
  Oman: 'Oman',
  'Once deleted the data cannot be restored':
    'Une fois les données supprimées, elles ne peuvent pas être restaurées',
  'Once deleted, the cases cannot be restored.':
    'Une fois supprimés, les cas ne peuvent pas être récupérés.',
  'Once removed the data cannot be restored':
    'Une fois supprimées, les données ne peuvent pas être restaurées',
  'Once the infrastructure is up, it will take our BOTs up to':
    "Une fois que l'infrastructure sera en place, il faudra à nos BOTs jusqu'à",
  One: 'Une',
  'One successful Advanced OSINT': 'Un OSINT avancé réussi',
  'One successful Intel Search': 'Une recherche de renseignements réussie',
  'One successful call log request for #{type} #{value} for the period of #{duration} months':
    'Une demande de journaux d’appels réussie pour #{type} #{value} pour la période de #{duration} mois',
  'One successful import cdr for #{type} #{value}':
    'Une importation réussie de CDR pour #{type} #{value}',
  Online: 'En ligne',
  'Only lowercase letters, numbers, dot (.) and underscore (_).':
    'Seulement des lettres minuscules, des chiffres, des points (.) et des tirets bas (_).',
  'Only xlsx or csv files are supported':
    'Seuls les fichiers xlsx ou csv sont pris en charge',
  'Open URL': "Ouvrir l'URL",
  'Open Web': 'Ouvrir le Web',
  'Open in a new tab': 'Ouvrir dans un nouvel onglet',
  'Open in new page': '',
  'Open in website': 'Ouvrir dans le site web',
  'Open remote view': 'Ouvrir la vue à distance',
  'Open target profile': 'Ouvrir le profil de la cible',
  'Opening browser': 'Ouverture du navigateur',
  Operator: 'Opérateur',
  'Opt-out': 'Se désabonner',
  Options: 'Options',
  Ordinal: 'Ordinal',
  Org: 'Org',
  Organic: 'Organique',
  Organization: 'Organisation',
  Organizations: 'Organisations',
  Origin: 'Origine',
  'Origin Position': "Position d'origine",
  'Origin position': "Position d'origine",
  Other: 'Autre',
  'Other info': 'Autre information',
  'Other recovery accounts': 'Autres comptes de récupération',
  'Other relations': 'Autres relations',
  'Other social profiles': 'Autres profils sociaux',
  'Other sources': 'Autres sources',
  Others: 'Autres',
  'Otherwise, your progress will not be saved': '',
  'Otp code obtained. Confirming facebook account registration':
    "Code Otp obtenu. Confirmation de l'enregistrement du compte Facebook",
  'Out SMS': 'SMS sortant',
  'Out call': 'Appel sortant',
  Outgoing: 'Sortant',
  'Outgoing SMS': 'SMS sortants',
  'Outgoing call': 'Appel sortant',
  'Outgoing sms': 'SMS sortant',
  'Outside of area': 'Hors de la zone',
  'Outside the circle': "À l'extérieur du cercle",
  Overall: 'Global',
  'Overall records': 'Enregistrements globaux',
  Overview: "Vue d'ensemble",
  Own: 'Propre',
  Owner: 'Propriétaire',
  'Owner id': 'ID du propriétaire',
  'PAGES WITH MATCHING IMAGES': 'PAGES AVEC DES IMAGES CORRESPONDANTES',
  'PARTIALLY MATCHING IMAGES': 'IMAGES PARTIELLEMENT CORRESPONDANTES',
  PASSWORD: 'MOT DE PASSE',
  'PDF layout': 'Disposition du PDF',
  'PDF settings updated successfully!':
    'Paramètres PDF mis à jour avec succès !',
  'POI type': 'Type de POI',
  'Page numbering': 'Numérotation des pages',
  Pakistan: 'Pakistan',
  Palau: 'Palaos',
  Palestine: 'Palestine',
  Panama: 'Panama',
  'Papua New Guinea': 'Papouasie Nouvelle Guinée',
  Paraguay: 'Paraguay',
  'Parsing error lines': "Lignes d'erreur d'analyse",
  'Parsing errors': "Erreurs d'analyse",
  Password: 'Mot de passe',
  'Password does not contain any digits.':
    'Le mot de passe ne contient pas de chiffres.',
  'Password does not contain lower case letters.':
    'Le mot de passe ne contient pas de lettres minuscules.',
  'Password does not contain upper case letters.':
    'Le mot de passe ne contient pas de lettres majuscules.',
  'Password expired': 'Le mot de passe a expiré',
  'Password must be at least 8 characters.':
    'Le mot de passe doit comporter au moins 8 caractères.',
  'Password provided was already used':
    'Le mot de passe fourni a déjà été utilisé',
  'Password strength': 'Force du mot de passe',
  "Passwords don't match.": 'Les mots de passe ne correspondent pas.',
  'Passwords not provided': 'Mots de passe non fournis',
  Past: 'Passée',
  'Past tasks': 'Tâches passées',
  'Paste here the url for the platform': "Collez ici l'url de la plateforme",
  'Paste image URL': "Coller l'URL de l'image",
  'Paste profile URL': "Coller l'URL du profil",
  Peer: 'Appelé',
  'Peer interactions': 'Interactions entre pairs',
  'Peer(s) locations': 'Emplacements des contacts',
  Pending: 'En attente',
  'Pending Request': 'Demande en attente',
  'Pending call log': 'Journal d’appels en attente',
  'Pending call log request!': 'Demande de journal d’appels en attente !',
  'Pending request': 'Demande en attente.',
  'Pending request. Click here to cancel':
    'Demande en attente. Cliquez ici pour annuler.',
  'Pending... Click to cancel': 'En attente... Cliquez pour annuler',
  People: 'Personnes',
  'People nearby': 'Personnes à proximité',
  Percent: 'Pourcentage',
  'Perform Location History': 'Effectuer un historique de localisation',
  'Perform a follow query for the selected rows':
    'Effectuer une requête de suivi pour les lignes sélectionnées',
  'Perform a location history query for the selected rows':
    "Effectuer une requête d'historique de localisation pour les lignes sélectionnées",
  'Perform a proximity query for the selected row':
    'Effectuer une requête de proximité pour la ligne sélectionnée',
  'Perform any of the OSINT Stage to retrieve important intelligence information':
    "Effectuez l'une des étapes OSINT pour récupérer des informations de renseignement importantes",
  Period: 'Période',
  'Periods analyzed': 'Périodes analysées',
  'Permanently delete case': 'Supprimer définitivement le cas',
  'Permanently delete investigation': 'Supprimer l’enquête de façon permanente',
  'Permanently delete target': 'Supprimer définitivement la cible',
  'Permanently remove target from case':
    'Supprimer définitivement la cible du cas',
  'Permission denied': 'Autorisation refusée',
  Person: 'Personne',
  'Persons tagged': 'Personnes étiquetées',
  Peru: 'Pérou',
  Philippines: 'Philippines',
  Phone: 'Téléphone',
  'Phone Info': 'Informations sur le téléphone',
  'Phone Model': 'Modèle de téléphone',
  'Phone Number': 'Numéro de téléphone',
  'Phone Numbers': 'Numéros de téléphone',
  'Phone brand': 'Marque de téléphone',
  'Phone links': 'Liens téléphoniques',
  'Phone model': 'Modèle de téléphone',
  'Phone models': 'Modèles de téléphone',
  'Phone models / IMEIs': 'Modèles de téléphone / IMEI',
  'Phone number': 'Numéro de téléphone',
  'Phone numbers': 'Numéros de téléphone',
  'Phone(s)': 'Téléphone(s)',
  Photo: 'Photo',
  'Photo update time': 'Heure de mise à jour de la photo',
  Photos: 'Photos',
  'Photos uploaded into facebook account. Filling account details':
    'Photos téléchargées sur le compte Facebook. Remplissage des détails du compte',
  Picture: 'Photo',
  'Pin this search': 'Épingler cette recherche',
  'Pitcairn Islands': 'Îles Pitcairn',
  Place: 'Lieu',
  'Place Of Birth': 'Lieu de naissance',
  'Place created successfully!': 'Lieu créé avec succès !',
  'Place has been successfully deleted': 'Le lieu a été supprimé avec succès',
  'Place has not been created': "Le lieu n'a pas été créé.",
  'Place has not been deleted': "Le lieu n'a pas été supprimé",
  'Place name': 'Nom du lieu',
  'Place of birth': 'Lieu de naissance',
  'Place of interest': "Lieu d'intérêt",
  Places: 'Lieux',
  'Places of interest': "Lieux d'intérêt",
  Platform: 'Plateforme',
  'Platform settings': 'Paramètres de la plateforme',
  'Please Enter Password': 'Veuillez entrer un mot de passe',
  'Please Enter Username': "Veuillez entrer un nom d'utilisateur",
  'Please add an email address to the user. A new password will be sent to that email':
    "Veuillez ajouter une adresse e-mail à l'utilisateur. Un nouveau mot de passe sera envoyé à cette adresse e-mail",
  'Please add at least two fields to create a note.':
    'Veuillez ajouter au moins deux champs pour créer une note.',
  'Please add email to enable email notifications':
    'Veuillez ajouter une adresse e-mail pour activer les notifications par e-mail',
  'Please add phone or email to enable OTP':
    'Veuillez ajouter un téléphone ou un e-mail pour activer OTP',
  'Please add phone to enable sms notifications':
    'Veuillez ajouter un téléphone pour activer les notifications par SMS',
  'Please choose another daterange.':
    'Veuillez choisir une autre plage de dates.',
  'Please complete all fields!': 'Veuillez remplir tous les champs !',
  'Please contact your administrator':
    'Veuillez contacter votre administrateur',
  'Please enable location access': 'Veuillez activer l’accès à la localisation',
  'Please enter 3 or more characters': 'Veuillez entrer 3 caractères ou plus',
  'Please enter a valid TRG email!':
    'Veuillez entrer une adresse email TRG valide !',
  'Please enter the OTP code to login.': '',
  'Please enter the URL for the specific community you want to be joined':
    'Veuillez saisir l’URL de la communauté spécifique à laquelle vous souhaitez vous joindre',
  'Please enter the URL for the specific community you want to be joined. &#10;One URL per line.':
    'Veuillez saisir l’URL de la communauté spécifique à laquelle vous souhaitez vous joindre. &#10;Une URL par ligne.',
  'Please enter the same new password again':
    'Veuillez entrer à nouveau le même nouveau mot de passe',
  'Please fill in all fields': 'Veuillez remplir tous les champs',
  'Please fill in both fields for the date':
    'Veuillez remplir les deux champs pour la date',
  'Please fill out this field': 'Veuillez remplir ce champ',
  'Please how do you want to create the Virtual ID': '',
  'Please insert more than one phones to perform a proximity query':
    "Veuillez insérer plus d'un numéro de téléphone pour effectuer une requête de proximité",
  'Please provide a short description of your work.':
    'Veuillez fournir une brève description de votre travail.',
  'Please provide proper platform link!':
    'Veuillez fournir un lien de plateforme approprié !',
  'Please provide your email address': 'Veuillez fournir votre adresse e-mail',
  'Please review the local file and upload again.': '',
  'Please select a 3 day range maximum.':
    'Veuillez sélectionner une plage de 3 jours maximum.',
  'Please select a Social Profile!': 'Veuillez sélectionner un profil social !',
  'Please select a date!': 'Veuillez sélectionner une date !',
  'Please select a query': 'Veuillez sélectionner une requête',
  'Please select all required values': '',
  'Please select at least 1 entity to create a new investigation':
    'Veuillez sélectionner au moins 1 entité pour créer une nouvelle enquête',
  'Please select at least 2 targets for call log analysis':
    "Veuillez sélectionner au moins 2 cibles pour l'analyse du journal d'appels",
  'Please select at least one profile to begin monitoring': '',
  'Please select the time period': 'Veuillez sélectionner la période de temps',
  'Please select the type of OTP you want to use.':
    'Veuillez sélectionner le type de OTP que vous souhaitez utiliser.',
  'Please select values for all columns': '',
  'Please take this survey to rate your experience with':
    'Veuillez répondre à cette enquête pour évaluer votre expérience avec',
  'Please use the filters below to define your objective. Keep in mind that you can make changes later if needed.':
    '',
  'Please use upper case letters, lower case letters and numbers':
    'Veuillez utiliser des lettres majuscules, des lettres minuscules et des chiffres.',
  'Please write the URL bellow and press enter if is valid': '',
  'Please write the URL bellow and press enter if valid': '',
  Poland: 'Pologne',
  Popularity: '',
  Portugal: 'le Portugal',
  Position: 'Poste',
  Positive: 'Positif',
  'Possible location for': 'Emplacement possible pour',
  'Possibly owns this social profile': 'Possède peut-être ce profil social',
  Post: 'Publication',
  'Post Information': 'Informations sur la publication',
  'Post is created': 'La publication est créée',
  'Post is disliked': 'La publication est pas aimée',
  'Post is liked': 'La publication est aimée',
  'Post is shared': 'La publication est partagée',
  'Post preview': 'Aperçu de la publication',
  'Post was added': 'Publication ajoutée',
  'Postal code': 'Code postal',
  'Posted on': 'Publié le',
  Posts: 'Publications',
  'Potential Infection': 'Infection Potentielle',
  'Potential infection': 'Infection Potentielle',
  'Power users': '',
  Powerusers: 'Utilisateurs avancés',
  'Pre registration warmup for #{socialType} started':
    'Pré-enregistrement pour #{socialType} commencé',
  'Pre-registering': 'Pré-enregistrement',
  'Predicted Home': 'Lieu de résidence prévu',
  'Predicted Home Location with probability of': 'Lieu de résidence prévu',
  'Predicted Location': 'Emplacement prévu',
  'Predicted Locations': 'Emplacements prévus',
  'Predicted Workplace': 'Lieu de travail prévu',
  'Predicted Workplace Location with probability of': 'Lieu de travail prévu',
  'Predicted location': 'Emplacement prévu',
  'Predicted locations': 'Emplacements prévus',
  'Preferred channel': 'Canal préféré',
  Pregnant: 'Enceinte',
  'Preparing PDF preview': "Préparation de l'aperçu PDF",
  Present: 'Présent',
  Presets: 'Préréglages',
  Previous: 'Précédent',
  'Previous page': 'Page précédente',
  Previously: 'Précédemment',
  'Private Profile': 'Profil privé',
  'Private message to': '',
  'Private messages': '',
  'Private profile': 'Profil privé',
  Probability: 'Probabilité',
  Proceed: 'Procéder',
  'Processing the data': 'Traitement des données',
  'Processing the data, please wait':
    'Traitement des données, veuillez patienter',
  Product: 'Produit',
  Profile: 'Profil',
  'Profile Search': '',
  'Profile Search Results': '',
  'Profile URL': 'URL du profil',
  'Profile URLs': 'URL de profil',
  'Profile is associated with this photo':
    'Le profil est associé à cette photo',
  'Profile is associated with this post':
    'Le profil est associé à cette publication',
  'Profile photos': 'Photos de profil',
  'Profile picture': 'Photo de profil',
  'Profile search history': 'Historique recherche profil',
  'Profile url for Facebook, Twitter, Instagram, Linkedin, Tiktok':
    'URL de profil pour Facebook, Twitter, Instagram, Linkedin, Tiktok',
  'Profile urls': '',
  Profiler: 'Profilage',
  'Profiler report': 'Rapport du profil',
  Profileurls: 'URL des réseaux sociaux',
  Profiling: '',
  'Profiling will be updated next month': '',
  Program: 'Programme',
  'Property last sale date(s)': 'Dernière(s) date(s) de vente de la propriété',
  'Property type': 'Type de propriété',
  'Property type(s)': 'Type(s) de propriété',
  Provider: 'Fournisseur',
  Proximity: 'Proximité',
  'Proximity Analysis': 'Analyse de proximité',
  Publish: 'Publier',
  'Purchase and import call log data for':
    'Acheter et importer des données de journal d’appels pour',
  'Purchase call log': 'Acheter le journal des appels',
  'Purchase call logs failed!': 'L’achat des journaux d’appels a échoué !',
  'Purchase call logs initiated!':
    'L’achat des journaux d’appels a été initié !',
  'QR code': 'Code QR',
  Qatar: 'Qatar',
  Quantity: 'Quantité',
  Queries: 'Requêtes',
  Query: 'Requête',
  'Query Date': 'Date de la requête',
  'Query Id': 'ID de requête',
  'Query MSISDN': 'Interroger le MSISDN',
  'Query and schedule': 'Interroger et planifier',
  'Query canceled': 'La requête a été annulée',
  'Query created successfully!': 'Requête créée avec succès !',
  'Query date': 'Date de la requête',
  'Query has not been canceled': "La requête n'a pas été annulée",
  'Query has not been created': "La requête n'a pas été créée.",
  'Query hashtag': 'Hashtag de la requête',
  'Query number': 'Numéro de requête',
  'Query pending': 'Requête en attente',
  'Query selected': 'Requête sélectionnée',
  'Query successfully resubmitted!': 'Requête réenvoyée avec succès !',
  'Query successfully submitted': 'Requête envoyée avec succès',
  'Quick filters': 'Filtres rapides',
  Quota: 'Quota',
  'Quota assigned': 'Quota assigné',
  'Quota unused': 'Quota inutilisé',
  Quotes: 'Citations',
  RFC: 'RFC',
  Race: 'Race',
  'Radical monitoring': 'Surveillance radicale',
  'Radical monitoring matched a keyword for':
    'La surveillance radicale a trouvé une correspondance pour',
  'Radical monitoring service is down':
    'Le service de surveillance radicale est hors ligne',
  'Radical speech monitoring': 'Surveillance des discours radicaux',
  Radio: 'Connexion',
  'Radio Id': '',
  'Radio Type': '',
  Radius: 'Rayon',
  Random: 'Aléatoire',
  'Random actions': 'Actions aléatoires',
  'Raw data': 'Données brutes',
  Reactions: 'Réactions',
  'Reactions count': 'Nombre de réactions',
  Reactivate: '',
  Reason: 'Raison',
  'Received Group Messages': '',
  'Received Private Messages': '',
  'Received group messages': '',
  'Received private messages': '',
  'Receiver number': 'Numéro de destinataire',
  'Recent searches': 'Recherches récentes',
  Recipient: 'Destinataire',
  'Recovery Accounts': 'Comptes de récupération',
  'Recovery accounts': 'Comptes de récupération',
  'Recovery data': 'Récupération de données',
  'Recovery email': 'Courriel de récupération',
  'Recovery phone': 'Téléphone de récupération',
  Redraw: 'Redessiner',
  'Redraw area': 'Redessiner la zone',
  Refresh: 'Actualiser',
  Region: 'Région',
  'registered on': '',
  'Registering FB acc.': 'Inscription au compte Facebook',
  'Registration date': 'Date d’inscription',
  'Related numbers': 'Numéros liés',
  'Related people': 'Personnes associées',
  'Related posts': 'Postes connexe',
  Relation: 'Relation',
  Relations: 'Relations',
  Releasing: 'Libération',
  Reload: 'Recharger',
  'Reload chart with latest target data':
    'Recharger le graphique avec les dernières données cibles',
  'Remaining quota': '',
  'Remaining usage': 'Remaining usage',
  'Remote view': 'Vue à distance',
  Remove: 'Supprimer',
  'Remove Profile': 'Supprimer le profil',
  'Remove filters': 'Supprimer les filtres',
  'Remove filters and restore queries':
    'Supprimer les filtres et restaurer les requêtes',
  'Remove from case': 'Supprimer du cas',
  'Remove from history': "Supprimer de l'historique",
  'Remove from whitelist': 'Supprimer de la liste blanche',
  'Remove layer': 'Supprimer la couche',
  'Remove node': '',
  'Remove target': 'Supprimer la cible',
  'Remove Target?': '',
  'Remove target from case': 'Supprimer la cible du cas',
  'Removes all filters, keeps new nodes from analyse':
    "Supprime tous les filtres, empêche l'analyse de nouveaux nœuds",
  'Removes everything (including nodes from analyse) and reloads all target data':
    "Supprime tout (y compris les nœuds de l'analyse) et recharge toutes les données cibles",
  Renew: 'Renouveler',
  'Renew target and Request call log':
    "Renouveler la cible et demander le journal d'appels",
  'Renew with the credits': 'Renouveler avec les crédits',
  Repeat: 'Répéter',
  'Repeat interval - hours': 'Répéter l’intervalle - heures',
  'Request Call Log': "Demander le journal d'appels",
  'Request Call Log by selecting one of the following options':
    'Demander un journal d’appels en sélectionnant l’une des options suivantes',
  'Request Cancelled': 'Demande annulée',
  'Request call log': 'Demander le journal des appels',
  'Request call log limit reached. Please contact support.':
    "Limite de demande de journal d'appels atteinte. Veuillez contacter le support.",
  'Request expired for': 'La demande a expiré pour',
  'Request from Support': 'Demande de support',
  'Request has been sent to support': 'La demande a été envoyée au support',
  'Request has not been sent': "La demande n'a pas été envoyée",
  'Request or Upload Call Log by selecting one of the following options':
    'Demander ou télécharger un journal d’appels en sélectionnant l’une des options suivantes',
  'Request sent successfully!': 'Demande envoyée avec succès !',
  'Requesting call logs has been disabled':
    'La demande de journaux d’appels a été désactivée',
  Required: 'Obligatoire',
  'Reserve browser': 'Reservar navegador',
  'Reserve browser failed. Please retry':
    'La réservation du navigateur a échoué. Veuillez réessayer',
  Reserved: 'Réservé',
  Reserving: 'Réservation',
  Reset: 'Réinitialiser',
  'Reset Password': 'Réinitialiser le mot de passe',
  'Resize map': 'Redimensionner la carte',
  'Rest of the week': 'Le reste de la semaine',
  Restore: 'Restaurer',
  Resubmit: 'Soumettre à nouveau',
  'Resubmit Cancelled': 'Réenvoi annulé',
  'Resubmit query': 'Réenvoyer la requête',
  'Result type': 'Type de résultat',
  Results: 'Résultats',
  'Results Found': 'Résultats Trouvés',
  Retrieved: '',
  'Results for': '',
  'Results found': 'Résultats trouvés',
  'Results of upload': 'Résultats du téléchargement',
  'Retweeted count': 'Nombre de retweets',
  Roaming: 'Roaming',
  'Roaming Provider': "Fournisseur d'itinérance",
  Robbery: 'Vol',
  'Robot has not been canceled': "Le robot n'a pas été annulé",
  Roles: 'Rôles',
  Romania: 'Roumanie',
  'Rows per page': 'Lignes par page',
  'Rows per page:': 'Lignes par page :',
  Run: 'Lancer',
  'Run method was successfully started':
    'La méthode de lancement a été lancée avec succès',
  Running: 'En cours d’exécution',
  Russia: 'Russie',
  Rwanda: 'Rwanda',
  'SAFE SEARCH': 'RECHERCHE SÉCURISÉE',
  'SIMILAR IMAGES': 'IMAGES SIMILAIRES',
  SMS: 'SMS',
  'SMS & calls': 'SMS et appels',
  'SMS activity': 'activité SMS',
  'SMS in': 'SMS entrant',
  'SMS out': 'SMS sortant',
  STATISTICS: 'STATISTIQUES',
  'Safety Cameras': 'Caméras de sécurité',
  'Sahrawi Arab Democratic Republic': 'République arabe sahraouie démocratique',
  'Saint Helena, Ascension and Tristan da Cunha':
    'Sainte-Hélène, Ascension et Tristan da Cunha',
  'Saint Kitts and Nevis': 'Saint-Christophe-et-Niévès',
  'Saint Lucia': 'Sainte-Lucie',
  'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
  'Same location': 'Même emplacement',
  Samoa: 'Samoa',
  'San Marino': 'Saint Marin',
  Satellite: 'Satellite',
  Saturday: 'Samedi',
  'Saudi Arabia': 'Arabie Saoudite',
  Save: 'Enregistrer',
  'Save and Locate': 'Enregistrer et localiser',
  'Save area': 'Enregistrer la zone',
  'Save area of Interest': "Enregistrer la zone d'intérêt",
  'Save as a list from': 'Enregistrer sous forme de liste à partir de',
  'Save changes': 'Enregistrer les changements',
  'Save data to target': 'Enregistrer les données pour la cible',
  'Save result': 'Enregistrer le résultat',
  'Saved!': 'Enregistré !',
  'Saving data': 'Enregistrement des données',
  'Scan QR with your device to generate OTP codes':
    'Scannez le QR avec votre appareil pour générer des codes OTP',
  'Scanned and added to feed': 'Scanné et ajouté à la liste',
  Scars: 'Cicatrices',
  Schedule: 'Programmation',
  'Schedule has been created': 'Le planning a été créé',
  Scheduled: 'Programmation',
  Scheduler: 'Programmateur',
  'Scrapping photos': 'Suppression des photos',
  'Screen name': "Nom d'écran",
  Search: 'Rechercher',
  Searches: '',
  'Search & geolocate phone': 'Rechercher & localiser téléphone',
  'Search & locate phone': '',
  'Search Google Maps': 'Rechercher sur Google Maps',
  'Search MSISDNs': 'Rechercher des MSISDN',
  'Search again': 'Rechercher à nouveau',
  'Search by': 'Rechercher par',
  'Search by face and name': 'Recherche par visage et nom',
  'Search by keyword': 'Rechercher par mot-clé',
  'Search by name': 'Rechercher par nom',
  'Search by phone number': 'Rechercher par numéro de téléphone',
  'Search entities': 'Rechercher des entités',
  'Search graph': 'Rechercher dans le graphique',
  'Search history': 'Historique de recherche',
  'Search in results': 'Rechercher dans les résultats',
  'Search keyword': 'Rechercher le mot-clé',
  'Search name, MSISDNs or cases': 'Rechercher un nom, un MSISDN ou des cas',
  'Search query': 'Terme de recherche',
  'Search tips': 'Conseils de recherche',
  'Search type': 'Type de recherche',
  'Search virtual ID ': 'Rechercher un identifiant virtuel',
  'Searched sources': 'Sources recherchées',
  'Searched sources for': '',
  'Searching closed databases': 'Recherche de bases de données fermées',
  'Searching dark web': 'Recherche sur le Dark Web',
  'Searching instant message profiles':
    'Recherche de profils de messagerie instantanée',
  'Searching open databases': 'Recherche de bases de données ouvertes',
  'Searching open web': 'Recherche sur le web ouvert',
  'Searching social media': 'Recherche dans les réseaux sociaux',
  'Searching social profiles': 'Recherche de profils sociaux',
  See: 'Voir',
  'See a demo investigation ': "Voir une démonstration d'enquête ",
  'See all photos': 'Voir toutes les photos',
  'See less': 'Voir moins',
  'See less results': 'Voir moins de résultats',
  'See more': 'Voir plus',
  'See more results': 'Voir plus de résultats',
  'See search history': "Voir l'historique de recherche",
  'Seed copied': 'Graine copiée',
  Select: 'Sélectionner',
  'Select Dates': 'Sélectionner les dates',
  'Select File': 'Sélectionner un fichier',
  'Select MSISDN': 'Sélectionner MSISDN',
  'Select Social Profile': 'Sélectionnez un profil social',
  'Select a range of max 3 days': 'Sélectionnez une plage de 3 jours maximum',
  'Select a target to update': 'Sélectionnez une cible à mettre à jour',
  'Select a value': 'Sélectionnez une valeur',
  'Select a virtual ID or proceed to the next step to create a new one':
    "Sélectionnez un identifiant virtuel ou passez à l'étape suivante pour en créer un nouveau",
  'Select accounts': '',
  'Select action': "Sélectionner l'action",
  'Select age': "Sélectionnez l'âge",
  'Select all': 'Tout sélectionner',
  'Select all available': 'Sélectionner tous les disponibles',
  'Select analysis': 'Sélectionner l’analyse',
  'Select area': 'Sélectionner la zone',
  'Select at least one period file':
    'Sélectionnez au moins un fichier de période',
  'Select atleast one IMEI/Phone model':
    'Sélectionnez au moins un modèle de téléphone/IMEI',
  'Select case': 'Sélectionner un dossier',
  'Select case targets': 'Sélectionner les cibles du cas',
  'Select country': 'Sélectionner le pays',
  'Select date': 'Sélectionner une date',
  'Select date range': '',
  'Select dates': 'Sélectionner des dates',
  'Select days': 'Sélectionner des jours',
  'Select entity': 'Sélectionner une entité',
  'Select file': 'Sélectionner un fichier',
  'Select frequency': 'Sélectionner la fréquence',
  'Select from Existing Targets': 'Sélectionner parmi les cibles existantes',
  'Select from pre-registered Virtual ID or create a new one': '',
  'Select gender': 'Sélectionnez le sexe',
  'Select layout': 'Sélectionner la mise en page',
  'Select list': 'Sélectionner la liste',
  'Select location': 'Sélectionner une localisation',
  'Select more targets': "Sélectionnez plus d'objectifs",
  'Select node to view details': 'Sélectionnez le nœud pour voir les détails',
  'Select one of multiple msisdns': 'Sélectionnez un ou plusieurs MSISDN',
  'Select one or multiple imsis': 'Sélectionnez un ou plusieurs IMSI',
  'Select one or multiple models': 'Sélectionnez un ou plusieurs modèles',
  'Select one or multiple msisdns': 'Sélectionnez un ou plusieurs MSISDN',
  'Select one or multiple periods': 'Sélectionnez une ou plusieurs périodes',
  'Select period': 'Sélectionner une période',
  'Select platform': 'Sélectionner la plateforme',
  'Select region': '',
  'Select report type': 'Sélectionner le type de rapport',
  'Select social accounts': '',
  'Select social network': 'Sélectionner le réseau social',
  'Select target': 'Sélectionner une cible',
  'Select targets': 'Sélectionner des cibles',
  'Select targets from case': 'Sélectionnez les cibles à partir du cas',
  'Select the start and end interval for tracking the Ad IDs':
    "Sélectionner l'intervalle de début et de fin pour suivre les ID publicitaires",
  'Select timeline analysis': 'Sélectionner l’analyse de la chronologie',
  'Select timezone': 'Sélectionnez le fuseau horaire',
  'Select type': 'Sélectionner le type',
  'Select user': 'Sélectionner un utilisateur',
  'Select/unselect location!': "Sélectionner/désélectionner l'emplacement !",
  Selected: 'Sélectionné',
  'Selected date must be in the past!':
    'La date sélectionnée doit être dans le passé !',
  'Selected queue': 'File d’attente sélectionnée',
  Send: 'Envoyer',
  'Send OTP': 'Envoyer un OTP',
  'Send SMS': 'Envoyer un SMS',
  'Send friend request': 'Envoyer une demande d’ami',
  'Send friend requests': 'Envoyer des demandes d’ami',
  'Send friend requests to group members':
    'Envoyer des demandes d’ami aux membres du groupe',
  'Send us your feedback!': 'Envoyez-nous vos commentaires !',
  Senegal: 'Sénégal',
  'Sent Group Messages': '',
  'Sent Private Messages': '',
  'Sent group messages': '',
  'Sent private messages': '',
  'Sent to groups': '',
  'Sent to persons': '',
  Sentiment: '',
  'Sentiment analysis': 'Analyse de sentiment',
  Sector: '',
  September: 'septembre',
  Sequencial: 'Séquentiel',
  Sequential: 'Séquentiel',
  Serbia: 'Serbie',
  Session: 'Session',
  'Session Timeout': 'Expiration de la session',
  'Session end': '',
  'Session has expired!': 'La session a expiré !',
  'Session length': '',
  'Session start': '',
  'Session started on the': 'Session commencée le',
  'Session will expire in': 'La session expirera dans',
  'Set Alerts': '',
  'Set notification for social activity': '',
  'Set notifications for social activity':
    'Set notifications for social activity',
  'Set social activity alerts': '',
  Settings: 'Paramètres',
  Severity: 'Gravité',
  Seychelles: 'les Seychelles',
  'Share in common': '',
  'Share post': 'Partager la publication',
  Shared: 'Partagé',
  Shortest: 'La plus courte',
  'Shortest call': 'Appel le plus court',
  'Show #{count} more': '',
  'Show Release Notes': 'Afficher les notes de version',
  'Show all': 'Afficher tout',
  'Show archived': 'Afficher les archives',
  'Show batch query': 'Afficher la requête par lot',
  'Show call log details': "Afficher les détails du journal d'appels",
  'Show call log map': "Afficher la carte du journal d'appels",
  'Show common IDs': 'Afficher des identifiants communs',
  'Show degrees': 'Mostrar grados',
  'Show direct interactions between selected MSISDNs': '',
  'Show existing intel': "Afficher l'intelligence existante",
  'Show history': "Afficher l'historique",
  'Show international interactions': '',
  'Show less': 'Afficher moins',
  'Show less cases': 'Afficher moins de cas',
  'Show more': 'Afficher plus',
  'Show more cases': 'Afficher plus de cas',
  'Show nearby Telegram users':
    'Afficher les utilisateurs Telegram à proximité',
  'Show nodes that came in contact with the target by distance of':
    'Afficher les nœuds qui ont été en contact avec la cible par une distance de',
  'Show nodes that interacted with the target by':
    'Afficher les nœuds qui ont interagi avec la cible par',
  'Show on map': 'Afficher sur la carte',
  'Show only articles with': 'Afficher articles avec',
  'Show only posts with': 'Afficher uniquement les publications contenant',
  'Show only profiles with': 'Afficher uniquement les profils avec',
  'Show only starred': 'Afficher uniquement les éléments sélectionnés',
  'Show predicted': 'Afficher la prédiction',
  'Show query details': 'Afficher les détails de la requête',
  'Show query history': "Afficher l'historique des requêtes",
  'Show search': 'Afficher la recherche',
  'Show statistics': 'Afficher les statistiques',
  'Show top associates': '',
  'Show unique IDs': 'Afficher des identifiants uniques',
  'Show updates': 'Afficher les mises à jour',
  'Sierra Leone': 'Sierra Leone',
  'Sign In': 'Se connecter',
  'Sign out': 'Se déconnecter',
  Sim: 'Carte SIM',
  'Simple OSINT': 'OSINT Simple',
  'Simple Query': 'Requête simple',
  'Simple details': 'Détails simples',
  Singapore: 'Singapour',
  'Site Name': 'Nom du site',
  'Site properties': 'Propriétés du site',
  Skills: 'Compétences',
  'Skin tone': 'Tonalité de la peau',
  Skip: 'Sauter',
  'Skip filtering': '',
  Skype: 'Skype',
  'Sleeping time': 'Temps de sommeil',
  Slovakia: 'Slovaquie',
  Slovenia: 'Slovénie',
  Sms: 'Sms',
  Social: 'Social',
  'Social Activity': '',
  'Social Insurance': 'Assurance sociale',
  'Social Media': 'Réseaux sociaux',
  'Social Media Profiles': 'Profils sur les réseaux sociaux',
  'Social Profile': 'Profil Social',
  'Social URL': 'URL sociale',
  'Social URLs': 'URL des réseaux sociaux',
  'Social Urls': 'URL sociales',
  'Social accounts': 'Comptes sociaux',
  'Social activity': '',
  'Social groups': 'Groupes sociaux',
  'Social insurance number': 'Numéro d’assurance sociale',
  'Social network': 'Réseau social',
  'Social network top associates': 'Principaux associés du réseau social',
  'Social networks': 'Réseaux sociaux',
  'Social profile': 'Profil social',
  'Social profile details': 'Détails du profil social',
  'Social profiles': 'Profils sociaux',
  'Social relation': 'Relation sociale',
  'Social word cloud': 'Nuage de mots sociaux',
  'Software Information': 'Informations sur le logiciel',
  'Solomon Islands': 'Les îles Salomon',
  Somalia: 'Somalie',
  Somedus: 'Somedus',
  'Somedus Request Submitted': 'Demande Somedus soumise',
  'Somedus retrived information': 'Somedus a récupéré des informations',
  'Somedus will be updated on 12:00': '',
  'Something went wrong': 'Quelque chose s’est mal passé',
  'Something went wrong please try again later!':
    "Quelque chose s'est mal passé, veuillez réessayer plus tard !",
  'Something went wrong with File Text Analysis': '',
  'Something went wrong with the photo. Please try again.':
    "Quelque chose s'est mal passé avec la photo. Veuillez réessayer.",
  'Something went wrong, please try again.':
    'Quelque chose s’est mal passé, veuillez réessayer.',
  'Something went wrong.': "Quelque chose s'est mal passé.",
  'Something went wrong. Please try OTP reset again.':
    "Quelque chose s'est mal passé. Veuillez réessayer la réinitialisation OTP.",
  'Something went wrong. Please try again.':
    "Quelque chose s'est mal passé. Veuillez réessayer.",
  Sort: 'Trier',
  'Sort by': 'Trier par',
  Source: 'Source',
  Sources: 'Sources',
  'South Africa': 'Afrique du Sud',
  'South Georgia and the South Sandwich Islands':
    'Géorgie du Sud et îles Sandwich du Sud',
  'South Korea': 'Corée du Sud',
  'South Sudan': 'Soudan du sud',
  Spain: 'Espagne',
  Spanish: 'Espagnol',
  'Special characters and trailing spaces are not allowed':
    'Les caractères spéciaux et les espaces de fin ne sont pas autorisés',
  'Special characters are not allowed':
    'Les caractères spéciaux ne sont pas autorisés',
  Specific: 'Spécifique',
  'Specific actions': 'Actions spécifiques',
  'Split View': 'Vue divisée',
  'Sri Lanka': 'Sri Lanka',
  Standard: 'Standard',
  'Starred only': '',
  Start: 'Démarrer',
  'Start Date': 'Date de début',
  'Start Investigation': 'Commencer l’investigation',
  'Start Scheduler': 'Démarrer le planificateur',
  'Start date': 'Date de début',
  'Start monitoring': 'Démarrer la surveillance',
  'Start now': 'Commencez maintenant',
  'Start scheduler': 'Démarrer le planificateur',
  'Start typing job description': 'Commencez à taper la description du travail',
  'Start typing phone, name, CURP, url, email or username or upload photo':
    'Commencez à taper le téléphone, le nom, le CURP, l’URL, l’email ou le nom d’utilisateur ou téléchargez une photo',
  'Start typing phone, name, url, email or username or upload photo':
    "Commencez à taper le téléphone, le nom, l'URL, l'e-mail ou le nom d'utilisateur ou téléchargez une photo",
  'Start typing your keyword': 'Commencez à taper votre mot-clé',
  'Start your investigation': '',
  'Started at': 'Commencé à',
  Starting: 'Démarrage du',
  Statistics: 'Statistiques',
  Status: 'Statut',
  'Status ': 'Statut',
  'Status of last 5 searches': '',
  Step: 'Étape',
  Stop: 'Arrêter',
  'Stop Geofencing Task': 'Arrêter la tâche de géorepérage',
  'Stop following': 'Arrêter de suivre',
  'Stop method was successfully stopped':
    'La méthode d’arrêt a été arrêtée avec succès',
  'Stop monitoring': 'Arrêter la surveillance',
  Stopped: 'Arrêté',
  Structural: 'Structural',
  Studied: 'A étudié',
  Submit: 'Soumettre',
  Subscription: 'Abonnement',
  'Succesfull ExtractusD Operation': 'Opération ExtractusD réussie',
  'Success!': 'Succès !',
  Successfully: '',
  'Successfully inserted': 'Inséré avec succès',
  Sudan: 'Soudan',
  Summary: 'Résumé',
  'Summary report': 'Rapport sommaire',
  Sunday: 'Dimanche',
  'Support request': 'Demande de support',
  'Supported file types': 'Types de fichiers pris en charge',
  Suriname: 'Suriname',
  Suspicious: '',
  'Suspicious Words': '',
  Swaziland: 'Swaziland',
  Sweden: 'Suède',
  'Switch to dark theme': 'Passer au thème sombre',
  'Switch to detail view': 'Passer à la vue détaillée',
  'Switch to light theme': 'Passer au thème clair',
  'Switch to standard view': 'Passer à la vue standard',
  Switzerland: 'Suisse',
  Syria: 'Syrie',
  'São Tomé and Príncipe': 'São Tomé et Príncipe',
  TMSI: 'TMSI',
  Tactical: 'Tactique',
  'Tagged In Same Photo': 'Tagué(s) sur la même photo',
  'Tagged by others': "Tagué par d'autres",
  'Tagged in': 'Identifié dans',
  'Tagged in photos of': 'Tagué dans les photos de',
  'Tagged in posts of': 'Tagué dans les publications de',
  'Tagged photos owners': 'Personnes identifiées dans les photos',
  'Tagged posts owners': 'Personnes identifiées dans les publications',
  Taiwan: 'Taïwan',
  Tajikistan: 'Tadjikistan',
  Tanzania: 'Tanzanie',
  Target: 'Cible',
  'Target Activity': 'Activité de la cible',
  'Target Details': 'Détails de la cible',
  'Target Edit': 'Modifier la cible',
  'Target Enriched successfully': 'Target Enriched successfully',
  'Target Expired': 'Cible expirée',
  'Target Infected': 'Cible Infectée',
  'Target LastSeen': 'Dernière vue de la cible',
  'Target Profile': 'Profil de la cible',
  'Target called': 'La cible a appelé',
  'Target cannot be renewed. Not enough Credits':
    'La cible ne peut pas être renouvelée. Crédits insuffisants',
  'Target case': 'Cas de la cible',
  'Target created successfully!': 'Cible créée avec succès !',
  'Target details': 'Détails de la cible',
  'Target edited successfully!': 'Cible modifiée avec succès !',
  'Target export call log report':
    "Exporter le rapport de journal d'appels de la cible",
  'Target has been successfully deleted':
    'La cible a été supprimée avec succès',
  'Target has not been created': "La cible n'a pas été créée.",
  'Target has not been deleted': "La cible n'a pas été supprimée",
  'Target has not been edited': "La cible n'a pas été modifiée.",
  'Target has not been renewed': "La cible n'a pas été renouvelée",
  'Target has not been renewed, Not enough credits':
    'La cible n’a pas été renouvelée, pas assez de crédits',
  'Target has not been update': "La cible n'a pas été mise à jour",
  'Target is Expired': 'La cible a expiré',
  'Target is already assigned to the case!':
    'La cible est déjà assignée au cas !',
  'Target is assigned to this case': 'La cible est assignée à ce cas',
  'Target is connected to the network': 'La cible est connectée au réseau',
  'Target is currently on a call': 'La cible est actuellement en appel',
  'Target is expired': 'La cible a expiré',
  'Target locations': 'Emplacements ciblés',
  'Target messaged': 'La cible a envoyé un message à',
  'Target number': 'Numéro cible',
  'Target peer interactions': 'Interactions entre pairs cibles',
  'Target received a call from': 'La cible a reçu un appel de',
  'Target received a message from': 'La cible a reçu un message de',
  'Target renewed successfully!': 'Cible renouvelée avec succès!',
  'Target summary': 'Résumé de la cible',
  'Target summary report': 'Rapport de synthèse de la cible',
  'Target updated successfully!': 'Cible mise à jour avec succès !',
  'Target {target} has a new post': '',
  'Target {target} has a new post with keyword(s)': '',
  Targets: 'Cibles',
  'Targets added successfully!': 'Cibles ajoutées avec succès !',
  'Targets are now here': 'Les cibles sont maintenant ici',
  'Targets assigned': 'Cibles assignées',
  'Targets renewed successfully!': 'Cibles renouvelées avec succès !',
  'Targets unassigned': 'Cibles non assignées',
  'Target’s subscription is active': "L'abonnement de la cible est actif",
  'Target’s subscription is active but currently not connected to the network':
    "L'abonnement de la cible est actif mais elle n'est actuellement pas connectée au réseau",
  'Target’s subscription is blocked': "L'abonnement de la cible est bloqué",
  'Target’s subscription is not activated':
    "L'abonnement de la cible n'est pas activé",
  'Task ID': 'ID de tâche',
  'Task Id': 'ID de la tâche',
  'Task for': 'Tâche pour',
  'Task has been successfully canceled': 'La tâche a été annulée avec succès',
  'Task has not been canceled': "La tâche n'a pas été annulée",
  Tasks: 'Tâches',
  Telegram: 'Telegram',
  'Telegram nearby': 'Telegram à proximité',
  'Telegram users cannot be located now!':
    'Les utilisateurs Telegram ne peuvent pas être localisés maintenant !',
  'Telegram users located successfully!':
    'Utilisateurs Telegram localisés avec succès !',
  Telnos: 'Numéros de téléphone',
  'Temporary password for #{username}':
    'Mot de passe temporaire pour #{username}',
  'Temporary password sent to: #{email}':
    'Mot de passe temporaire envoyé à : #{email}',
  Tenant: 'Locataire',
  'Tenant balance': 'Solde du locataire',
  Text: 'Texte',
  'Text Analysis': 'Analyse de texte',
  'Text analysis': 'Analyse de texte',
  'Text analysis the file': '',
  Thailand: 'Thaïlande',
  'Thank you for your message. Your customer success representative will contact you shortly':
    'Merci pour votre message. Votre représentant de succès client vous contactera prochainement',
  'The Bahamas': 'Les Bahamas',
  'The Cell ID cannot be extracted for':
    "L'ID de cellule ne peut pas être extrait pour",
  'The Netherlands': 'Les Pays-Bas',
  'The OSINT action for': "L'action OSINT pour",
  'The files once deleted cannot be restored.':
    'Les fichiers supprimés ne peuvent pas être récupérés.',
  'The inputted #{clParameterType} is partially invalid. Please update your request with the following #{clParameterType}:':
    'Le #{clParameterType} saisi est partiellement invalide. Veuillez mettre à jour votre demande avec le #{clParameterType} suivant :',
  'The last x days': 'Les x derniers jours',
  'The monitor is collecting data, it will be presented as soon as it is discovered':
    "Le moniteur collecte des données, elles seront présentées dès qu'elles seront découvertes",
  'The password was changed!': 'Le mot de passe a été changé!',
  'The password was reset!': 'Le mot de passe a été réinitialisé!',
  'The password will be sent to the email address #{email}. Is this address correct?':
    "Le mot de passe sera envoyé à l'adresse e-mail #{email}. Cette adresse est-elle correcte ?",
  'The quota is changed by #{currentUser}':
    'La quota est modifiée par #{currentUser}',
  'The quota is changed by #{currentUser} - #{quotaType}':
    'La quota est modifiée par #{currentUser} - #{quotaType}',
  'The request call log is not currently supported for this country':
    "La demande de journal d'appels n'est pas actuellement prise en charge pour ce pays.",
  'The requested phone number is not valid with this country code. Please select a different country code from the dropdown menu and try again.':
    '',
  'The system will generate a new Avatar': '',
  'The target changes have been saved and analysis is started. Click':
    "Les changements de la cible ont été enregistrés et l'analyse a commencé. Cliquez sur",
  'The target changes have been saved. Click':
    'Les changements de la cible ont été enregistrés. Cliquez',
  'The whole Facebook registration flow completed':
    "Le processus d'inscription complet à Facebook est terminé",
  'Then you have the ability to:': 'Ensuite, vous avez la capacité de :',
  'There are no MSISDNs added. Please add a':
    'Aucun MSISDN ajouté. Veuillez ajouter un',
  'There are no avatars created. Please add a new avatar':
    "Aucun avatar n'a été créé. Veuillez ajouter un nouvel avatar.",
  'There are no cases created. Please add a':
    'Aucun cas créé. Veuillez ajouter un',
  'There are no investigations added. Please create a ':
    "Aucune enquête n'a été ajoutée. Veuillez créer une ",
  'There are no investigations added. Please create a new investigation': '',
  'There are no ip created': '',
  'There are no suspicious words': '',
  'There are no targets created. Please add a':
    'Aucune cible créée. Veuillez ajouter une',
  'There are no virtual IDs assigned to your user':
    "Aucun identifiant virtuel n'est attribué à votre utilisateur",
  'This Virtual ID will expire on': 'Cet identifiant virtuel expirera le',
  'This action might leave anonymous traces on target’s phone.':
    'Cette action pourrait laisser des traces anonymes sur le téléphone de la cible.',
  'This action will create a new target and request call log for':
    "Cette action créera une nouvelle cible et demandera le journal d'appels pour",
  'This action will create the Locate query that is associated with':
    'Cette action va créer la requête de localisation associée à',
  'This action will deduct 2 credits.': 'Cette action déduira 2 crédits.',
  'This action will deduct 3 credits.': 'Cette action déduira 3 crédits.',
  'This action will deduct 4 credits. This may take up to 30 min.':
    "Cette action déduira 4 crédits. Cela peut prendre jusqu'à 30 minutes.",
  'This action will enable Call log analysis':
    'Cette action permettra l’analyse du journal des appels',
  'This action will fetch all the Basic OSINT that are associated with':
    'Cette action va récupérer tous les OSINT basiques associés à',
  'This action will fetch call log information for':
    "Cette action récupérera les informations du journal d'appels pour",
  'This action will locate the': 'Cette action localisera le',
  'This action will purchase and import call log for':
    'Cette action achètera et importera le journal des appels pour',
  'This action will purchase the call logs for':
    'Cette action achètera le journal des appels pour',
  'This action will request Call Log for':
    'Cette action demandera le journal des appels pour',
  'This action will show the accurate position of the Telegram nearby people':
    'Cette action montrera la position précise des personnes proches de Telegram',
  'This associate frequently interacts with the target.':
    'Cet associé interagit fréquemment avec la cible.',
  'This associate possibly met with the target.':
    'Cet associé a peut-être rencontré la cible.',
  'This case has no targets': "Ce cas n'a pas de cibles",
  'This case needs at least 2 targets to find common information':
    "Ce cas a besoin d'au moins 2 cibles pour trouver des informations communes !",
  'This is a 24hrs temporary user':
    "Il s'agit d'un utilisateur temporaire de 24 heures.",
  'This monitor did not collect any data':
    "Ce moniteur n'a pas collecté de données",
  'This option will be available for': 'Cette option sera disponible pour',
  'This post contains': 'Cette publication contient',
  'This process might take approximately':
    'This process might take approximately',
  Thursday: 'Jeudi',
  Tiktok: 'Tiktok',
  Time: 'Temps',
  'Time Zone': 'Fuseau horaire',
  'Time left to expiration:': 'Temps restant avant expiration:',
  'Time of collection': 'Heure de collecte',
  'Time of the query': 'Heure de la requête',
  'Time remaining': 'Temps restant',
  'Time stamp': 'Horodatage',
  'Timebar type': 'Type de barre de temps',
  Timegraph: '',
  timeline: '',
  Timeline: 'Chronologie',
  'Timeline pattern': 'Motif de la chronologie',
  Title: 'Titre',
  'Title content': 'Contenu du titre',
  'Title exists. Please use another title':
    'Ce titre existe déjà. Veuillez en utiliser un autre',
  'Title is required': 'Le titre est requis',
  To: 'À',
  'To achieve this we are asking you to participate in the user feedback generation program where every now-and-then we would ask you simple questions about how you use Intellectus.':
    'Pour cela, nous vous demandons de participer au programme de génération de commentaires des utilisateurs, où de temps en temps, nous vous poserons des questions simples sur la façon dont vous utilisez Intellectus.',
  'To import, please select one or multiple days':
    'Pour importer, veuillez sélectionner un ou plusieurs jours',
  'To search for a phone number, enter the complete number, including country code. Example: #{example}':
    'Pour rechercher un numéro de téléphone, entrez le numéro complet, y compris le code pays. Exemple : #{example}',
  'To start a monitor': 'Pour commencer une surveillance',
  Today: "Aujourd'hui",
  'Toggle map': 'Basculer la carte',
  'Toggle Mission Overview': '',
  'Toggle photo': 'Basculer la photo',
  Togo: 'Aller',
  Tokelau: 'Tokélaou',
  Tonga: 'Tonga',
  'Tool to analyze different types of intelligence':
    'Outil pour analyser différents types d’intelligence',
  'Top 5 contacts': '',
  'Top Associates': 'Associés principaux',
  'Top Commenteers In Facebook Photos':
    'Principaux commentateurs sur les photos Facebook',
  'Top Commenteers In Facebook Posts':
    'Top des commentateurs de publications sur Facebook',
  'Top Device Attributes': 'Attributs de périphérique les plus importants',
  'Top Facebook Connections': 'Principales connexions Facebook',
  'Top Facebook Photo Shares': 'Top des partages de photos sur Facebook',
  'Top Facebook Post Shares': 'Top des partages de publications sur Facebook',
  'Top Keywords': 'Top Keywords',
  'Top Likers In Facebook Photos': 'Top des Likers de photos sur Facebook',
  'Top Likers In Facebook Posts':
    'Principaux "j’aime" sur les publications Facebook',
  'Top Locations': 'Emplacements principaux',
  'Top Relations': 'Relations principales',
  'Top associates': 'Associés principaux',
  'Top connection': 'Connexion importante',
  'Top locations': 'Top des lieux',
  'Top profiles': 'Profils les plus populaires',
  'Top relationships': '',
  'Top target locations': 'Principales localisations cibles',
  Topic: 'Sujet',
  Topics: 'Sujets',
  Total: '',
  'Total Parsed': 'Total analysé',
  'Total points': '',
  'Total calls': 'Total des appels',
  'Total credits': 'Crédits totaux',
  'Total credits deducted': 'Total des crédits déduits',
  'Total credits reserved': 'Crédits totaux réservés',
  'Total distance': 'Distance totale',
  'Total duration': 'Durée totale',
  'Total monitor time': 'Temps total de surveillance',
  'Total number of matches': 'Nombre total de correspondances',
  'Total number of sessions': 'Nombre total de sessions',
  'Total queries': 'Total des requêtes',
  'Total successful queries': 'Total des requêtes réussies',
  'Total transactions': '',
  Trace: 'Tracer',
  'Track period': '',
  'Transaction date': '',
  Transactions: '',
  'Transactions summary': '',
  'Trilateration query completed!': 'Requête de trilatération terminée!',
  'Trinidad and Tobago': 'Trinité-et-Tobago',
  'True Caller': 'True Caller',
  Truecaller: 'Truecaller',
  'Truecaller profile location': 'Emplacement du profil Truecaller',
  'Try another search': 'Essayez une autre recherche',
  'Try different or less filters': 'Essayez des filtres différents ou moins',
  'Try fewer keywords': 'Essayez avec moins de mots clés',
  Tuesday: 'Mardi',
  Tunisia: 'Tunisie',
  Turkey: 'Turquie',
  Turkmenistan: 'Turkménistan',
  'Turks and Caicos Islands': 'îles Turques-et-Caïques',
  'Turn Off': 'Désactiver',
  'Turn On': 'Activer',
  Tuvalu: 'Tuvalu',
  Tweak: 'Ajustement',
  Twitter: 'Twitter',
  'Twitter hashtag': 'Hashtag Twitter',
  Type: 'Type',
  'Type a name': 'Tapez un nom',
  'Type any term you would like to add':
    'Saisissez tout terme que vous souhaitez ajouter',
  'Type of data': '',
  'Type keyword': '',
  'Type keywords you wish to follow': '',
  'Type value here': 'Tapez la valeur ici',
  UNKNOWN: 'INCONNU',
  URL: 'URL',
  'USER EQUIPMENT': "Équipement de l'utilisateur",
  USERNAME: "NOM D'UTILISATEUR",
  Uganda: 'Ouganda',
  Ukraine: 'Ukraine',
  Unauthorized: 'Non autorisé',
  Unavailable: 'Non disponible',
  'Uncover usernames, open web mentions, email and social media accounts':
    'Découvrez les noms d’utilisateur, les mentions sur le web, les comptes de messagerie et les réseaux sociaux',
  'Uncover usernames, open web mentions, email and social media accounts that are associated with':
    'Découvrez les noms d’utilisateur, les mentions sur le web, les comptes de messagerie et les réseaux sociaux associés à',
  Unfollow: 'Ne plus suivre',
  Unique: '',
  'Unique entries': 'Entrées uniques',
  'United Arab Emirates': 'Emirats Arabes Unis',
  'United Kingdom': 'Royaume-Uni',
  'United States': 'États-Unis',
  'United States of America': "Les états-unis d'Amérique",
  Unknown: 'Inconnu',
  'Unknown type': 'Type inconnu',
  'Unlike group posts': 'Ne plus aimer les publications de groupe',
  'Unlike posts': 'Ne plus aimer les publications',
  Unlimited: 'Illimité',
  Unlock: 'Déverrouiller',
  Unmapped: '',
  'Unmerge results': 'Dissocier les résultats',
  'Unpin this search': 'Détacher cette recherche',
  Unreachable: 'Inaccessible',
  'Unselect all': 'Tout désélectionner',
  Unspecified: 'Non spécifié',
  'Unveil movement patterns and understand your target’s habits':
    'Découvrez les habitudes de mouvement et comprenez les habitudes de votre cible',
  'Unveil movement patterns and understand your target’s habits and associates':
    'Découvrez les habitudes de mouvement, comprenez les habitudes et les contacts de votre cible',
  Update: 'Mettre à jour',
  'Update existing target': 'Mettre à jour la cible existante',
  'Update link analysis': "Mettre à jour l'analyse de lien",
  'Update target': 'Mettre à jour la cible',
  'Update the graph': 'Mettre à jour le graphique',
  'Upload Call Log': "Télécharger le journal d'appels",
  'Upload Call Log by selecting one of the following options':
    'Télécharger un journal d’appels en sélectionnant l’une des options suivantes',
  'Upload another file': 'Télécharger un autre fichier',
  'Upload call log': "Télécharger le journal d'appels",
  'Upload call log for: #{value}':
    'Télécharger le journal des appels pour : #{value}',
  'Upload existing call': "Télécharger un journal d'appels existant",
  'Upload existing call log': "Téléchargez un journal d'appels existant",
  'Upload image': 'Télécharger une image',
  'Upload photo': 'Télécharger une photo',
  'Uploaded on': '',
  Url: 'Url',
  Usage: '',
  'Use at least 8 characters. Don’t use a password from another site, or something too obvious':
    "Utilisez au moins 8 caractères. Ne choisissez pas un mot de passe d'un autre site, ou quelque chose de trop évident.",
  'Use comma to separate the MSISDNs.':
    'Utilisez une virgule pour séparer les MSISDN.',
  'Use comma or enter to add MSISDNs or IMSIs': '',
  'Use the search filters': 'Utiliser les filtres de recherche',
  'Use this option to search for a pre-existing Virtual ID suitable to your mission, or create a new Virtual ID':
    '',
  'Used by': 'Utilisé par',
  User: 'Utilisateur',
  'User Equipment': "Équipement de l'utilisateur",
  'User ID': 'Identifiant utilisateur',
  'User Management': 'Gestion des utilisateurs',
  'User created with': 'Utilisateur créé avec',
  'User edited successfully!': 'Utilisateur modifié avec succès !',
  'User equipment': 'Équipement utilisateur',
  'User has been successfully deleted':
    "L'utilisateur a été supprimé avec succès",
  'User has no wallets': '',
  'User has not been created': "L'utilisateur n'a pas été créé",
  'User has not been edited': "L'utilisateur n'a pas été modifié",
  'User locked successfully!': 'Utilisateur verrouillé avec succès!',
  'User management': 'Gestion des utilisateurs',
  'User manual': 'Manuel utilisateur',
  'User not found': 'Utilisateur introuvable',
  'User options': 'Options utilisateur',
  'User settings': 'Paramètres utilisateur',
  'User unlocked successfully!': 'Utilisateur déverrouillé avec succès!',
  'User:': 'Utilisateur :',
  Username: "Nom d'utilisateur",
  'Username already exists. Please try again.':
    'Le nom d’utilisateur existe déjà. Veuillez réessayer.',
  'Username must contain at least one letter':
    "Le nom d'utilisateur doit contenir au moins une lettre",
  'Username(s)': "Nom(s) d'utilisateur",
  Usernames: "Noms d'utilisateur",
  Users: 'Utilisateurs',
  'Valid email entered': 'Adresse email valide',
  'Valid phone number entered.': 'Numéro de téléphone valide entré.',
  Value: 'Valeur',
  'Vehicle ID': 'Identifiant de véhicule',
  'Vehicle ID (VIN)': 'ID du véhicule (VIN)',
  'Vehicle make': 'Marque de véhicule',
  'Vehicle model': 'Modèle du véhicule',
  'Vehicle models': 'Modèles de véhicules',
  'Vehicle year': 'Année du véhicule',
  Vendor: 'Vendeur',
  Verified: 'Vérifié',
  Video: 'Vidéo',
  Videos: 'Vidéos',
  View: 'Voir',
  'View All History': '',
  'View Call Log analysis': "Voir l'analyse du journal d'appels",
  'View Cases': '',
  'View Dashboard': 'Voir le tableau de bord',
  'View Log': 'Voir le journal',
  'View Targets': '',
  'view all': '',
  'View all commenters': 'Voir tous les commentateurs',
  'View all likers': "Voir tous les J'aime",
  'View all queries': '',
  'View call log': 'Voir le journal d’appels',
  'View call log analysis': 'Voir l’analyse du journal des appels',
  'View details': 'View details',
  'View full profile': 'Voir le profil complet',
  'View geolocation details': 'Voir les détails de géolocalisation',
  'View in Link Analysis': "Voir dans l'analyse de liens",
  'View in fullscreen': 'Afficher en plein écran',
  'View in link analysis': 'Voir dans l’analyse de liens',
  'View item': "Voir l'élément",
  'View less': 'Voir moins',
  'View map': 'Voir la carte',
  'View more': 'Voir plus',
  'View note details': 'Afficher les détails de la note',
  'View on map': 'Voir sur la carte',
  'View in Google maps': '',
  'View original post': 'Voir la publication originale',
  'View photo': 'Voir la photo',
  'View place': 'Voir lieu',
  'View post': 'Voir la publication',
  'View post details': 'Voir les détails de la publication',
  'View profile': 'Voir le profil',
  'View results': 'Voir les résultats',
  'View session details': 'Voir les détails de la session',
  'View Settings': '',
  'View short profile': 'Voir le profil abrégé',
  'View target': 'Voir la cible',
  'View the list': '',
  'View the results': 'Voir les résultats',
  'View the target': 'Voir la cible',
  Violence: 'Violence',
  'Virtual ID': 'ID virtuelles',
  'Virtual ID  management': 'Gestion des identifiants virtuels',
  'Virtual ID #{fullName} expired on #{formatedDate}. Click on the button below to renew for #{numberOfDays} days.':
    '',
  'Virtual ID could not be renewed. Please try again.': '',
  'Virtual ID creation wizard': "Assistant de création d'identifiant virtuel",
  'Virtual ID information': "Informations sur l'identifiant virtuel",
  'Virtual ID management': 'Gestion des ID virtuelles',
  'Virtual ID renewed successfully.': '',
  'Virtual ID selection': "Sélection d'identifiant virtuel",
  'Virtual ID was created': "L'identifiant virtuel a été créé",
  'Virtual ID was updated': 'ID virtuel mis à jour',
  'Virtual IDs': 'Identifiants virtuels',
  'Virtual IDs have expired': '',
  'Virtual IDs will expire in 24 hours': '',
  'Virtual id': 'Identifiant virtuel',
  'Visit Target': 'Visiter la cible',
  Voice: 'Voix',
  Volunteering: 'Bénévolat',
  'Volunteering actions': 'Actions de bénévolat',
  'Voter ID': 'ID de l’électeur',
  'Voter Id': 'ID de vote',
  'WEB ENTITIES': 'ENTITÉS WEB',
  WEBINT: 'WEBINT',
  'Waiting for CSV to be downloaded':
    'En attente du téléchargement du fichier CSV',
  'Wall posts': 'Postes de mur',
  'Wallet addresses': '',
  Wallets: '',
  'Wanted for': 'Recherché pour',
  Warning: '',
  'We are always looking for the ways to improve our product for it to be even more useful in your daily work.':
    'Nous cherchons toujours des moyens d’améliorer notre produit pour qu’il soit encore plus utile dans votre travail quotidien.',
  'We couldn’t find any results for': "Nous n'avons trouvé aucun résultat pour",
  'We found': 'Nous avons trouvé',
  "We found an anomaly! This person used the target's mobile device.":
    'Nous avons trouvé une anomalie ! Cette personne a utilisé le téléphone mobile de la cible.',
  'We found available historical data':
    'Nous avons trouvé des données historiques disponibles',
  'We found available historical data for':
    'Nous avons trouvé des données historiques disponibles pour',
  'We found that': 'Nous avons trouvé que',
  'We have successfully merged all the collected data to a':
    'Nous avons fusionné avec succès toutes les données collectées en un',
  'We now support search by CURP ID':
    'Nous prenons désormais en charge la recherche par ID CURP',
  'We sent a verification code to the email address:':
    'Nous avons envoyé un code de vérification à l’adresse e-mail:',
  'We sent a verification code to the phone number:':
    'Nous avons envoyé un code de vérification au numéro de téléphone:',
  'We were not able to bring company employees at the moment.':
    "Nous n'avons pas pu apporter des employés de la société pour le moment.",
  'We were not able to bring mutual facebook friends at the moment.':
    "Nous n'avons pas pu apporter des amis Facebook mutuels pour le moment.",
  'We will notify you once the enrichment is completed': '',
  Webint: 'Webint',
  'Webint features are disabled. Please contact your customer success representative.':
    'Les fonctionnalités Webint sont désactivées. Veuillez contacter votre représentant de succès client.',
  'Webint search history': 'Historique de recherche Webint',
  Wednesday: 'Mercredi',
  Week: 'Semaine',
  Weekdays: 'Jours de semaine',
  Weekend: 'Week-end',
  Weekends: 'Fins de semaine',
  'Weekly activity': 'Activité hebdomadaire',
  Weight: 'Poids',
  'What is the objective of your investigation?': '',
  'What type of investigation are you working on?':
    'Sur quel type d’enquête travaillez-vous ?',
  Whatsapp: 'Whatsapp',
  When: '',
  'When using the search filters, the results will be more accurate':
    "Lors de l'utilisation des filtres de recherche, les résultats seront plus précis",
  Where: '',
  Whitelist: 'Liste blanche',
  'Whitelist Number': 'Numéro de liste blanche',
  'Whitelist Numbers': 'Numéros de la liste blanche',
  Who: '',
  Withdrawal: '',
  'Word Cloud': '',
  Wordcloud: 'Nuage de mots',
  Work: 'Travail',
  'Work Location': 'Lieu de travail',
  'Work Place': 'Lieu de travail',
  'Work Place(s)': 'Lieu(x) de travail',
  'Work of art': "Oeuvre d'art",
  'Work place': 'Lieu de travail',
  'Work relations': 'Relations professionnelles',
  'Working time': 'Temps de travail',
  Workplace: 'Lieu de travail',
  Workplaces: 'Lieux de travail',
  'Would you like to a create a schedule': 'Souhaitez-vous créer un planning',
  'Would you like to help us become even better?':
    'Souhaitez-vous nous aider à devenir encore meilleurs ?',
  'Would you like to import more data':
    'Souhaitez-vous importer plus de données',
  'Would you like to obtain AGPS/GPS location for':
    'Souhaitez-vous obtenir la localisation AGPS/GPS pour',
  'Would you like to obtain more accurate location for':
    'Souhaitez-vous obtenir une localisation plus précise pour',
  'Would you like to save the changes you made in this investigation?':
    'Voulez-vous enregistrer les modifications apportées à cette enquête ?',
  'Would you like to save the latest changes you made in link analysis?':
    "Voulez-vous enregistrer les derniers changements que vous avez apportés à l'analyse de liens ?",
  'Would you like to see target profile': 'Voulez-vous voir le profil cible',
  'Wrong Credentials': 'Identifiants incorrects',
  'Wrong password': 'Mot de passe incorrect',
  'Wrong template.': 'Modèle incorrect.',
  'X hours and Y minutes.': '',
  'X minutes': '',
  'Y hours': '',
  Yes: 'Oui',
  'Yes, I want to cancel': 'Oui, je veux annuler',
  'Yes, I want to participate': 'Oui, je veux participer',
  Yesterday: 'Hier',
  'You are investigating': '',
  ' call logs.': '',
  'From the available CDR files, select the data you would like to load into the Explorer':
    '',
  'You can select individual targets by selecting the custom button.': '',
  'You can select multiple nodes by holding down Ctrl key and clicking on a node.':
    'Vous pouvez sélectionner plusieurs nœuds en maintenant la touche Ctrl enfoncée et en cliquant sur un nœud.',
  'You can turn off': 'Vous pouvez désactiver',
  'You can use the Apps button to navigate to the Cases & Targets page. By clicking done, you will permanently hide this message':
    'Vous pouvez utiliser le bouton Applications pour accéder à la page "Cas et cibles". En cliquant sur "Terminé", vous masquerez définitivement ce message.',
  'You do not have access rights or the url is invalid':
    "Vous n'avez pas les droits d'accès nécessaires ou l'URL est invalide",
  "You don't have any monitoring created":
    "Vous n'avez créé aucune surveillance",
  'You dont have permissions to use this feature':
    "Vous n'avez pas les autorisations nécessaires pour utiliser cette fonctionnalité",
  'You have': 'Vous avez',
  'You have entered some invalid ad ids.':
    'Vous avez entré des ID de publicité invalides.',
  'You have more than 1024 characters': 'Vous avez plus de 1024 caractères',
  'You have more than 255 characters': 'Vous avez plus de 255 caractères',
  'You have reached': 'Vous avez atteint',
  'You have reached the limit of cases used in the system. To add a new case, please go to the list of cases to delete one or contact your local administrator.':
    '',
  'You have reached the limit of targets used in the system. To add a new target, please go to the list of targets to delete one or contact your local administrator.':
    '',
  'You have reached the limit of the Cases used in the system.To add a new one, please go to the list of Cases and delete one.':
    '',
  'You have reached the limit of the Targets used in the system.To add a new one, please go to the list of targets and delete one.':
    '',
  'You have reached the limit of the cases used in the system. Contact your local administrator':
    '',
  'You have reached the limit of the targets and cases used in the system. Contact your local administrator':
    '',
  'You have reached the limit of the targets used in the system. Contact your local administrator.':
    '',
  'You have to save the investigation before uploading a file':
    'Vous devez enregistrer l’enquête avant de télécharger un fichier',
  'You ran out of available cases. Contact your local administrator.':
    'You ran out of available cases. Contact your local administrator.',
  'You ran out of available cases. To add a new case, please go to the': '',
  'You ran out of available targets and cases. Contact your local administrator':
    '',
  'You ran out of available targets and cases. Contact your local administrator.':
    'You ran out of available targets and cases. Contact your local administrator.',
  'You ran out of available targets. Contact your local administrator.':
    'You ran out of available targets. Contact your local administrator.',
  'You ran out of available targets. To add a new target, please go to the': '',
  'You selected to change OTP method from application to the following email address':
    "Vous avez sélectionné de changer la méthode OTP de l'application à l'adresse email suivante",
  'You selected to change OTP method from application to the following phone number':
    "Vous avez sélectionné de changer la méthode OTP de l'application au numéro de téléphone suivant",
  'You selected to change OTP method from email to application':
    "Vous avez choisi de changer de méthode OTP de l'e-mail à l'application",
  'You selected to change OTP method from email to the following phone number':
    "Vous avez choisi de changer de méthode OTP de l'e-mail au numéro de téléphone suivant",
  'You selected to change OTP method from phone number to application':
    "Vous avez sélectionné de changer la méthode OTP du numéro de téléphone à l'application",
  'You selected to change OTP method from phone number to the following email address':
    "Vous avez sélectionné de changer la méthode OTP du numéro de téléphone à l'adresse email suivante",
  'You will be redirected to login screen.':
    "Vous serez redirigé vers l'écran de connexion.",
  'Your alerts have been set successfully': '',
  'Your browser does not support video':
    'Votre navigateur ne prend pas en charge la vidéo',
  'Your browser is not capable to view in fullscreen. Please switch to a latest browser.':
    "Votre navigateur ne prend pas en charge l'affichage en plein écran. Veuillez passer à un navigateur plus récent.",
  'Your feedback is valuable!': 'Vos commentaires sont précieux !',
  'Your feedback is very valuable and will help us to improve Intellectus. We would appreciate if you stayed!':
    'Vos commentaires sont très précieux et nous aideront à améliorer Intellectus. Nous apprécierions que vous restiez !',
  'Your opinion is very important': 'Votre opinion est très importante',
  'Your password expired yesterday.': 'Votre mot de passe a expiré hier.',
  'Your password expires today.': "Votre mot de passe expire aujourd'hui.",
  'Your password expires tomorrow.': 'Votre mot de passe expire demain.',
  'Your password has expired #{days} ago.':
    'Votre mot de passe a expiré il y a #{days} jours.',
  'Your password will expire in #{days} days.':
    'Votre mot de passe expirera dans #{days} jours.',
  'Your query has been queued': "Votre requête a été mise en file d'attente",
  'Your request for SOMEDUS on': 'Votre demande de SOMEDUS sur',
  'Your session is about to expire click "Continue" to keep the session active.':
    'Votre session est sur le point d\'expirer, cliquez sur "Continuer" pour maintenir la session active.',
  '[trilateration_nearby_people]': '',
  "a playback of the target's timeline":
    'une lecture de la chronologie de la cible',
  'account has been submitted.': 'a été soumise.',
  active: 'actif',
  'active members': 'active members',
  'active relationships found': '',
  'active relationships found out of': '',
  actions: 'actions',
  add: 'ajouter',
  addresses: 'adresses',
  'Additional parameters': '',
  'AdId copied': '',
  'AdId session': '',
  'added to': '',
  advanced: 'avancé',
  advanced_osint: 'OSINT avancé',
  all: 'tous',
  and: 'et',
  'and delete one.': '',
  'and ended on': "et s'est terminé le",
  'and ended on the': 'et terminée le',
  'annual quota': 'annual quota',
  area: 'zone',
  at: 'à',
  'at year #{year}': 'de l’année #{year}',
  authentication: 'authentification',
  authored: 'auteur',
  'available of your queries quota': 'disponibles de votre quota de requêtes',
  away: 'loin',
  'between 50-100 meters': 'entre 50-100 mètres',
  'between hours #{hour.start}:00 and #{hour.end}:00':
    'entre les heures #{hour.start}:00 et #{hour.end}:00',
  'between the #{day.start}st and #{day.end}th day of a month':
    'entre le #{day.start}er et le #{day.end}ème jour d’un mois',
  'between the #{day.start}th and #{day.end}th day of a month':
    'entre le #{day.start}ème et le #{day.end}ème jour d’un mois',
  'birth location': '',
  call: 'appel',
  call_info: 'Informations sur l’appel',
  call_log_purchase: 'Achat de journaux d’appels',
  call_log_request_msisdn: 'Demande de journaux d’appels',
  call_log_upload_imei: 'Téléchargement réussi du CDR IMEI',
  call_log_upload_msisdn: 'Téléchargement de journaux d’appels MSISDN',
  calls: '',
  cancel: 'Annuler',
  case: 'cas',
  case_management: 'Gestion des cas',
  cases: 'cas',
  'cases and targets': 'cas et cibles',
  cell_id: 'ID de cellule',
  changed: 'changée',
  'check in': 'lieux à',
  'click to expand.': 'cliquez pour agrandir.',
  'closed databases': 'Bases de données fermées',
  comments: 'commentaires',
  countries: 'pays',
  'create a new target': 'créer une nouvelle cible',
  'create-user': 'créer un utilisateur',
  created: 'créé',
  'created:': 'créé :',
  credit: 'Crédit',
  credits: 'crédits',
  'credits assigned': 'crédits assignés',
  'credits available': 'crédits disponibles',
  'credits by': 'crédits par',
  'current and previous workplaces (target)':
    'Lieu de travail actuel et précédent (Cible)',
  'daily quota': 'daily quota',
  darkweb: 'Darkweb',
  'data activity': 'activité de données',
  'data points': 'points de données',
  'data used at': 'données utilisées à',
  day: 'jour',
  days: 'jours',
  'days remaining until expiration': "jours restants avant l'expiration",
  delete: 'supprimer',
  destination: 'destination',
  details: 'détails',
  disabled: 'désactivée',
  discovery: 'Découverte',
  duration: 'durée',
  'duration & frequency': 'durée et fréquence',
  'education (target)': 'Éducation (Cible)',
  educations: 'éducations',
  email: 'e-mail',
  emails: 'adresses e-mail',
  emergency: 'urgence',
  empty: 'vide',
  enabled: 'activée',
  'end of history': "fin de l'histoire",
  'enter keywords': 'entrez des mots-clés',
  entered: 'entré',
  'entries were imported': 'entrées ont été importées',
  errors: 'erreurs',
  'expires at dd/mm/yyy hh:mm': '',
  female: 'féminin',
  'filter by location': 'filtrer par emplacement',
  'filter out the selected nodes by pressing the Delete key.':
    'filtrer les nœuds sélectionnés en appuyant sur la touche Supprimer.',
  filters: 'filtres',
  for: 'pour',
  'for at least 2 targets from the case in order to see call log analysis':
    "pour au moins 2 cibles du cas et pouvoir voir l'analyse du journal d'appels.",
  'for the objective': 'pour la cible',
  'for:': 'pour:',
  found: 'trouvé',
  frequency: 'fréquence',
  friday: 'vendredi',
  from: 'de',
  'from Platform settings.': 'à partir des paramètres de la plateforme.',
  'full name': 'nom complet',
  gender: 'genre',
  'geo location': 'emplacement géographique',
  'geo-fencing': 'géo-clôture',
  geolocation: 'géolocalisation',
  group: 'groupe',
  groups: '',
  has: 'a',
  'has been captured on': 'a été capturé le',
  'has failed': '',
  'has family relation with': 'a une relation familiale avec',
  'has finished': 'a terminé',
  'has terminated': 'a été interrompu',
  'has used by the same device': 'a été utilisé par le même appareil',
  here: 'ici',
  high: 'élevé',
  histogram: 'histogramme',
  'home location': '',
  home_mcc: 'MCC',
  home_mnc: 'MNC',
  hour: 'heure',
  hours: 'heures',
  'hours to respond with success status.':
    'heures pour répondre avec un état de succès.',
  idle: 'inactif',
  image: 'image',
  images: 'images',
  imei: 'IMEI',
  imeis: 'imeis',
  import_cdr: "Importation du journal d'appels",
  import_historical_locations: 'Importation d’emplacements historiques',
  imsi: 'IMSI',
  in: 'dans',
  'in order to see call log analysis':
    "afin de pouvoir consulter l'analyse du journal d'appels",
  inactive: 'inactif',
  incoming: 'entrant',
  'incoming & outgoing': 'entrant et sortant',
  info: 'information',
  inside: "à l'intérieur",
  "insights about your target's top associates":
    'statistiques sur les principaux associés de votre cible',
  intel: 'renseignement',
  intel_search: "recherche d'intelligence",
  'interacted with': 'a interagi avec',
  investigation: 'enquête',
  is: 'est',
  'is already included in': '',
  'is Offline. Last Known Location found':
    'est hors ligne. Dernière localisation connue trouvée',
  'is deactivated': 'est désactivé',
  'is done': 'est terminée',
  'is expired': 'a expiré',
  'is here': 'est ici',
  'is in': 'est dans',
  'is not a valid': "n'est pas valide",
  'is not a valid MSISDN .': "n'est pas un MSISDN valide .",
  'is not a valid MSISDN.': "n'est pas un MSISDN valide.",
  'is on emergency': "est en situation d'urgence",
  'is required': 'est requis',
  item: 'article',
  'item selected': 'éléments sélectionnés',
  items: 'articles',
  'items found': 'éléments trouvés',
  'items on': 'articles sur',
  'items selected': 'articles sélectionnés',
  jobs: 'emplois',
  keyword: 'mots-clés',
  'keyword list': 'liste de mots-clés',
  lac: 'LAC',
  'last 30 days': '30 derniers jours',
  'last 60 days': '60 derniers jours',
  'last 90 days': '90 derniers jours',
  'last seen': '',
  left: 'quitté',
  less: 'moins',
  'less than 50 meters': 'moins de 50 mètres',
  likes: "j'aime",
  links: 'liens',
  'list of cases': '',
  'list of targets': '',
  'list selected': 'liste sélectionnée',
  'lists selected': 'listes sélectionnées',
  live: 'En direct',
  'locate all': 'localiser tout',
  located: 'localisé',
  'located at #({latitude},{longitude})':
    'localisé à #({latitude},{longitude})',
  location: 'localisation',
  'location found': 'localisation trouvée',
  locked: 'verrouillée',
  login: 'Connexion',
  logon_query_location_offline:
    'Connexion à la requête de localisation hors ligne',
  logout: 'Déconnexion',
  low: 'faible',
  male: 'masculin',
  media: 'media',
  medium: 'moyen',
  members: 'members',
  mentions: '',
  messages: 'messages',
  'messages exchanged': '',
  'message exchanged': '',
  meters: 'mètres',
  'meters or less': 'mètres ou moins',
  minimize: 'réduire',
  minute: 'minute',
  minutes: 'minutes',
  Mission: 'Mission',
  monday: 'lundi',
  month: 'mois',
  'monthly quota': 'monthly quota',
  months: 'mois',
  more: 'plus',
  'move the selected nodes by dragging using the mouse or with the keyboard arrows.':
    'déplacer les nœuds sélectionnés en les faisant glisser avec la souris ou avec les flèches du clavier.',
  msisdn: 'MSISDN',
  name: 'nom',
  'name middle surname': 'nom deuxième nom',
  'name surname': 'nom prénom',
  names: 'noms',
  'nearby profiles found': 'profils à proximité trouvés',
  'nearby towers found': 'tour(s) à proximité trouvée(s)',
  neighbour_cell_towers: 'Tours de cellules voisines',
  new: 'nouveau',
  'new MSISDN': 'nouveau MSISDN',
  'new case': 'nouveau cas',
  'new investigation': 'nouvelle enquête',
  'new post': '',
  'new target': 'nouvelle cible',
  nmr: 'NMR',
  nodes: 'nœuds',
  'not located': 'non localisé',
  notifications: 'notifications',
  'number could not be located': "numéro n'a pas pu être localisé",
  'number successfully located': 'numéro localisé avec succès',
  of: 'de',
  'of your queries quota': 'de votre quota de requêtes',
  on: 'sur',
  'on #{dayName}s': 'les #{dayName}s',
  'on #{monthName}': 'le #{monthName}',
  or: 'ou',
  'or ': 'ou ',
  'otp-email': 'otp e-mail',
  'otp-phone': 'otp téléphone',
  'out of': 'sur',
  outgoing: 'sortant',
  outside: "à l'extérieur",
  'over 100 meters': 'plus de 100 mètres',
  overall: 'global',
  'overall activity': 'activité globale',
  'para el objetivo': '',
  'passwords found': 'mots de passe trouvés',
  'people nearby': 'personnes à proximité',
  'persons tagged': 'personnes étiquetées',
  phone: 'téléphone',
  phone_model: 'Modèle',
  photo: 'photo',
  'photo details': 'détails de la photo',
  'photo(s) together': 'photo(s) ensemble',
  photos: 'photos',
  'platform selected': 'plateforme sélectionnée',
  'platforms selected': 'plateformes sélectionnées',
  post: 'publication',
  'post the last': 'publication des dernières',
  posts: 'publications',
  'posts the last': 'publications des dernières',
  'profile location': 'localisation du profil',
  profileUrls: 'URL de profil',
  profiles: 'Profils',
  published: 'publié',
  queries: 'requêtes',
  query: 'requête',
  query_foreign_location: 'Recherche de localisation étrangère',
  query_location: 'Emplacement de la requête',
  query_location_offline: 'Requête de localisation hors ligne',
  queue: "file d'attente",
  quota: 'crédits',
  'quota reset to initial balance': 'Quota réinitialisé au solde initial',
  'ramining targets': '',
  reactions: 'Réactions',
  recipient: 'destinataire',
  records: 'enregistrements',
  register: "S'inscrire",
  'remaining cases': 'remaining cases',
  'remaining daily quota': '',
  'remaining monthly quota': '',
  'remaining targets': 'remaining targets',
  'remote browser': 'navigateur à distance',
  'removed successfully': '',
  renewed: 'renouvelé',
  'request cdr': 'Demande de CDR',
  reset: 'réinitialiser',
  'reset-password': 'réinitialiser le mot de passe',
  results: 'résultats',
  'results found': 'résultats trouvés',
  roles: 'rôles',
  saturday: 'samedi',
  saved: 'enregistré',
  'scheduled task has finished': 'la tâche programmée est terminée',
  'screenHeight must not be less than 480':
    "La hauteur de l'écran ne doit pas être inférieure à 480",
  'screenWidth must not be less than 640':
    "La largeur de l'écran ne doit pas être inférieure à 640",
  'search history': 'historique de recherche',
  'search results found for': 'résultats de recherche trouvés pour',
  second: 'second',
  seconds: 'secondes',
  seeds: 'graines',
  'seeds selected': 'graines sélectionnées',
  'select all': 'sélectionner tout',
  selected: 'sélectionnée',
  sender: 'expéditeur',
  set: 'ensemble',
  shares: 'partages',
  'show less': 'Montrer moins',
  'Show Mission Overview': "Afficher l'aperçu de la mission",
  'show more': 'afficher plus',
  'single unique profile': 'profil unique',
  'social platforms': 'les plateformes sociales',
  source: 'source',
  sources: '',
  space: 'espace',
  'started at': 'débutée à',
  'started on': 'débuté le',
  starting: 'démarrage',
  status: 'État',
  sub: 'sous',
  successful: 'réussi',
  successfully: 'avec succès',
  'sucessfully logged in': 'connecté avec succès',
  'sucessfully logged out': 'déconnecté avec succès',
  sunday: 'dimanche',
  target: 'cible',
  'target photo(s)': 'photo(s) cible',
  'target post(s)': 'publication(s) cible',
  "target's common used routes":
    'les itinéraires les plus couramment utilisés par la cible',
  "target's predicted locations": 'prévisions de localisations de la cible',
  target_management: 'Gestion des cibles',
  targets: 'objectifs',
  'targets found': 'cibles trouvées',
  telno: 'número de teléfono',
  telnos: 'numéros de téléphone',
  'text analysis': '',
  'the extention supported are:': '',
  'the geofenced area': 'la zone de géolocalisation',
  thursday: 'jeudi',
  time: 'fois',
  'time(s)': 'fois',
  times: 'fois',
  'times or more': 'fois ou plus',
  to: 'à',
  'to be monitored, or create a': 'à surveiller, ou créez une',
  'to reuse and select': 'à réutiliser et sélectionnez',
  'to the target to be able to use this feature':
    'à la cible pour pouvoir utiliser cette fonctionnalité',
  'to view the target profile': 'pour voir le profil de la cible',
  trilateration_nearby_people: 'Trilatération de personnes à proximité',
  tuesday: 'mardi',
  type: 'type',
  'unknown type': 'type inconnu',
  unlocked: 'débloquée',
  'update-user': "mettre à jour l'utilisateur",
  'upload existing call log': "Télécharger un journal d'appels existant",
  url: 'url',
  user: 'utilisateur',
  username: "nom d'utilisateur",
  usernames: "noms d'utilisateur",
  via: '',
  videos: 'vidéos',
  views: 'vues',
  'voice activity': 'activité vocale',
  was: 'était',
  'was Succesfull': 'a été réussie',
  'was extracted successfully. Information was also added in Target Call Log Analysis':
    "a été extrait avec succès. Les informations ont également été ajoutées à l'analyse du journal d'appels de la cible",
  'was found': 'a été trouvé',
  'was found near': 'a été trouvé près de',
  'was located during a call': 'a été localisé pendant un appel',
  'was located during a call.': "a été localisé lors d'un appel.",
  'was successfully delivered': 'a été livré avec succès',
  'was turned off': 'a été désactivé',
  'was turned on': 'a été activé',
  wednesday: 'mercredi',
  week: 'semaine',
  weekdays: 'jours de la semaine',
  weekend: 'week-end',
  weekends: 'week-ends',
  'will be updated at 12:00am': 'will be updated at 12:00am',
  'will be updated next month': 'will be updated next month',
  'will expire soon': '',
  with: 'avec',
  'with ID:': 'avec ID :',
  'with a duration of': 'avec une durée de',
  'with msisdn': 'avec msisdn',
  'workplace location': '',
  'x out of y': '',
  'you want to be monitored': 'que vous souhaitez surveiller',
  'your browser': 'votre navigateur',
  'All of your request for SOMEDUS on': '',
  'Once the infrastructure for each request is up, it will take our BOTs up to':
    '',
  'You have already requested Somedus for this target': '',
  'Try out the new and mobile friendly Discovery!': '',
  'Display on map': '',
  'Display only sector': '',
  'Latest location for': '',
  'Location for': '',
  '#{numberOf} days ago': '',
  '#{numberOf} hours ago': '',
  '#{numberOf} minutes ago': '',
  '#{numberOf} seconds ago': '',
  'a few seconds ago': '',
  'display on map': '',
  'No profiles found': '',
  QUERIES: 'REQUETES',
  'Latest profile search': '',
  Track: '',
  'a minute ago': '',
  'an hour ago': '',
  'a day ago': '',
  subscription: 'abonnement',
  network: 'réseau',
  'Meet the new Discovery!': '',
  "On our quest to enhance your experience we're updating the discovery module to match your needs and expectations.":
    '',
  'Focus on your mission': '',
  'Track the movements of several targets simultaneously on a single map.': '',
  'Explore key moments of your Mission': '',
  'With the time bar, you can easily focus on specific times in a mission to reveal important information.':
    '',
  'Missions on the Go': '',
  'Take your missions with you on any device, mobile or tablet.': '',
  'Dive Right In!': '',
  'Ready to explore? Start adding new and historical MSISDNs to the mission screen.':
    '',
  'Find Profile': '',
  Tracking: '',
  'Tracking information': '',
  Every: '',
  until: '',
  'You are currently tracking': '',
  'based on the below parameters.': '',
  'Locating for': '',
  'Locating every': '',
  'Scheduled Searches': '',
  'Executed Searches': '',
  'Locations Found': '',
  'Terminate tracker': '',
  'Tracking terminated for': '',
  scheduled: '',
  '#{numberOf} minutes': '',
  '#{numberOf} hours': '',
  '#{numberOf} days': '',
  'Maximum count of phone numbers inside the mission has been reached.': '',
  'On a call with': '',
  Retrieve: '',
  Retrieving: '',
  'On a call': '',
  'Incoming call from': '',
  'Outgoing call to': '',
  logon: '',
  'Something went wrong during termination of tracker for': '',
  'End when completed': '',
  'Terminate if location found': '',
  'Add label': '',
  'Edit label': '',
  Label: '',
  'Label already exists': '',
  'Add label to': '',
  'Edit label to': '',
  'Nearby cell towers': '[Nearby cell towers]',
  available: '[available],',
  TERMINATED: '',
  COMPLETED: '',
  'Scheduled Type': '',
  'Scheduled searches': '',
  'Executed searches': '',
  'Locations found': '',
  'Terminated by user': '',
  Terminated: '',
  'Active Trackers only': '',
  Terminate: '',
  'Terminate Tracker': '',
  'You are about to terminate the tracking of': '',
  'Click Terminate to continue or Close to go back': '',
  'Locate phone & find profiles': '',
  'Set up tracking': '',
  'Set up scheduled tracking for': '',
  'Set up scheduled tracking': '',
  'Specify tracking duration and frequency': '',
  'Locate for': '',
  'Locate every': '',
  'Terminate tracking when first location is found': '',
  'Start Tracking': '',
  'Tracking started for': '',
  'Case is empty. Click the': '',
  'Maximum count of phone numbers inside the case has been reached.': '',
  'Focus on your case': '',
  'Explore key moments of your Case': '',
  'With the time bar, you can easily focus on specific times in a case to reveal important information.':
    '',
  'Cases on the Go': '',
  'Take your cases with you on any device, mobile or tablet.': '',
  'Ready to explore? Start adding new and historical MSISDNs to the cases screen.':
    '',
  'Show Case Overview': '',
  'Please enter 3 or more characters (up to 20 characters in total)': '',
  'Add to mission': '',
  'Schedule Completed': '',
  'Subscription deactivated': '',
  'Terminated by system': '',
  'Query info': '',
  'Query performed by': '',
  'Submitted on': '',
  'Query submitted on': '',
  'Query ID': '',
  'Query ID copied!': '',
  'No Call logs found': '',
  'Loading Call logs': '',
  'Not currently available on mobile - coming soon.': '',
  'Unexpected platform error.': '',
  'The registered country of this number is not included in your license.': '',
  'The operator of this number is not included in your license.': '',
  'This number currently cannot be located.': '',
  'Your limit of location searches has been reached.': '',
  'You do not have sufficient credits.': '',
  'Number cannot be found.': '',
  'Number found, but location could not be identified.': '',
  'Operator  is not currently covered.': '',
  'Cell ID found but coordinates are not available.': '',
  'This feature is not included in your license.': '',
  'We have been notified. Please try again later.': '',
  'Not possible to locate the number.': '',
  'Wait until your limit is reset and try again.': '',
  'Please contact your administrator.': '',
  'Please try again later.': '',
  'Location of': '',
  'Tracking of': '',
  'found (Tracking terminated)': '',
  'is on a call. Retrieve caller?': '',
  'Caller successfully retrieved': '',
  'Sector successfully calculated': '',
  'Nearby cell towers successfully retrieved': '',
  'Subscription of': '',
  'deactivated. (Tracking terminated)': '',
  completed: '',
  'Not possible to locate this number.': '',
  '': '',
  'has been added.': '',
  'has been removed.': '',
  'Subscription has been DEACTIVATED': '',
  'view details': '',
  'Add group to Case': '',
  'Create or add to case': '',
  'The group has been saved to case "#{caseName}". Click view to go to the case.':
    '',
  'Advertisement intelligence': 'Advertisement intelligence',
  'New draft': 'New draft',
  'Add new target MSISDN, IMSI from the input below or from your recent searches':
    '',
  'MSISDN/IMSI or a Target': '',
  'MSISDN/IMSI': '',
  'Case name': 'Nombre del Caso',
  'Enter the case name': '',
  'Case name is required': '',
  'Short description': '',
  'Enter a short description of the case': '',
  'A short description is required': '',
  Saving: '',
  'Please add targets before create new Case': '',
  'We saved your current case and we are redirecting you to the selected case.':
    '',
  'We saved your current case and we are redirecting you to the new draft.': '',
  'We saved your current case.': '',
  'Something went wrong during saving case process. Please try again.': '',
  'Select Case': '',
  'Case not found': '',
  'Request type': '',
  Discrete: '',
  Explicit: '',
  'Accept risk and proceed': '',
  'Are you sure you would like to proceed?': '',
  'You have selected #{explicitRequestsCount} #{requestWord} to run explicitly. Explicit Somedus requests may finish faster. However, it increases the risk that the target will become aware of a takeover attempt, which will risk the activity. In case of an unsuccessful explicit attempt, it is possible that the requested accounts will not be available for additional requests.':
    '',
  request: '',
  requests: '',
  'Select an account for which you would like to execute a Somedus request': '',
  "Target's account": '',
  'First name': '',
  optional: '',
  'Execution type': '',
  'Select email': '',
  control: '',
  Control: '',
  'type first name': '',
  'type last name': '',
  'Your request for Somedus on': '',
  'account has been submitted': '',
  'to respond with success status': '',
  'associated accounts': '',
  'e.g. Kidnapping': '',
  'Open in Discovery': '',
  'has been removed from case.': '',
  'view group details': '',
  'Show targets': '',
  'Plot groups': '',
  'Select groups': '',
  'Group name': '',
  Participants: '',
  'MSISDN in multiple groups': '',
  'Search participant': '',
  'Group Analysis': '',
  'GROUP DETAILS': '',
  'No content': '',
  Chat: 'Chat',
  'Data analysis is underway.': '',
  'Thank you for your patience as we process the information.': '',
  'Top keywords': '',
  'Word cloud': '',
  Multimedia: 'Multimedia',
  'Search chat': '',
  'View group details': '',
  'Group Details': '',
  'Add group to an existing case flow': '',
  'Add to existing case': '',
  'view more': '',
  view: '',
  participants: '',
  'Link copied': '',
  'MSISDN copied': '',
  'User removed': '',
  'MFA Password/key Protected Account': '',
  'A linked email must be provided for this account': '',
  'A correct fullname must be provided for this account': '',
  'The selected account is not valid': '',
  'MSISDN is Deactivated': '',
  'No account found for the requested MSISDN': '',
  'Takeover blocked due to spam, retry in 2 days': '',
  'Account does not support SMS 2FA': '',
  'No data found for this account': '',
  'Must perform Control first': '',
  'Provided name was invalid': '',
  'Latest location': '',
  'Area selected': '',
  'Please select an area on the map': '',
  'Retrieving the data': '',
  'Start analysing': '',
  Searching: '',
  'Name of the search': '',
  'Use the map tools to draw an Area of Interest': '',
  'Analyse specific Ad IDs': '',
  'Enter Ad ID': '',
  'Enter specific ad ids': '',
  'Select start and end date': '',
  'Customized Search': '',
  'Select Parameter': '',
  Parameter: '',
  'Customise your search in the Area of interest by adding metadata as parameters with their value':
    '',
  'App used': '',
  'Device type': '',
  'Device ip': '',
  'Connection type': '',
  'Session created': '',
  'Session results for "#{sessionName}" are ready': '',
  'Type the value': '',
  'Get deeper insights. Start running Somedus requests today': '',
  Refine: '',
  'Total data points': '',
  'Copy and paste the url of an image here': '',
  'Add the name of the person that depict in the uploaded image': '',
  'Search by image': '',
  'Search image': '',
  'the extension supported are: .jpg, jpg, .png': '',
  'Remove file': '',
  'Add to': '',
  Rename: '',
  Updating: '',
  'Rename session': '',
  'Session name': '',
  'e.g. Airport': '',
  'Session has been renamed': '',
  'Session name already exists': '',
  'Remove Session?': '',
  'Are you sure you want to remove this session?': '',
  'Session has been removed': '',
  TRACKERS: '',
  'Discovery History': '',
  'Created At': '',
  failed: '',
  pending: '',
  expired: '',
  'access granted': '',
  'Traveled to': '',
  'but no location found': '',
  'Target was last located in': '',
  'Target was last seen in': '',
  ago: '',
  'Returned to': '',
  'The previous location of the target was in': '',
  'History of locations abroad': '',
  'Traveled from': '',
  'Target was last located abroad in': '',
  before: '',
  'History of devices': '',
  'Copy ID': '',
  'Retrieved MSISDNs': '',
  'Changed device': '',
  License: 'Licence',
  'Photo details': 'Détails de la photo',
  'Would you like to save the changes you made in this case?': '',
  'Deleted target': '',
  'Date interval': '',
  'View summary': '',
  'Multi sessions': '',
  'Filtering the data': '',
  'Add Filters To Multi Sessions': '',
  'Apply filters': '',
  'Add Filters': '',
  'App Publisher Id': '',
  'Device Type': '',
  'Device Make': '',
  'Device Model': '',
  'Device OS': '',
  'Device OS Version': '',
  'Device Language': '',
  'User Agent': '',
  'User Year Of Birth': '',
  'User YOB': '',
  City: '',
  Isp: '',
  BSSID: '',
  SSID: '',
  'Cell MCC': '',
  'Cell MNC': '',
  'Cell LAC': '',
  'Isp Type': '',
  'Sim Carrier': '',
  'Sim Country Code': '',
  'GPS Accuracy': '',
  Parameters: '',
  'WEBINT (Offline)': 'WEBINT (Offline)',
  'WEBINT (Online)': 'WEBINT (Online)',
  'Query type': '',
  'Query type is': '',
  'Historical locations': '',
  date: '',
  dates: '',
  'with locations for this number on': '',
  'with locations for this number between': '',
  Jan: '',
  Feb: '',
  Mar: '',
  Apr: '',
  Jun: '',
  Jul: '',
  Aug: '',
  Sep: '',
  Oct: '',
  Nov: '',
  Dec: '',
  'Choose the dates that you like to import': '',
  locations: '',
  clear: '',
  'Successfully imported': '',
  'historical location to': '',
  'historical locations to': '',
  'The maximum amount of characters are': '',
  Private: 'Privé',
  Public: 'Publique',
  '#{targetsCount} of your targets participate in the "#{caseName}" groups': '',
  '#{targetsCount} of your targets participate in the "#{caseName}" group': '',
  'Add targets to Case': '',
  'Targets found': '',
  'Opened Clusters': '',
  'Frequent Ad IDs': '',
  Occurrence: '',
  'Ad ID': 'Ad ID',
  'IP Addresses': '',
  SSIDs: 'SSIDs',
  BSSIDs: 'BSSIDs',
  'This device was used by': '',
  'other unknown': '',
  'You can view them by clicking on the button.': '',
  'unknown MSISDN': '',
  'unknown MSISDNs': '',
  'Devices used with': '',
  'Devices with multiple MSISDNs': '',
  'IMEI copied!': '',
};
